import { Account } from '@packages/models/api';
import amplitude from 'amplitude-js';
import { ConfigService, Constants } from '../config';
import { Platform } from 'react-native';

export enum ANALYTICS_EVENTS {
    USER_CLICKS_TO_LOGIN = 'USER_CLICKS_TO_LOGIN',
    USER_SUCCESSFULLY_LOGS_IN = 'USER_SUCCESSFULLY_LOGS_IN',
    USER_CLICKS_CREATE_A_QMR = 'USER_CLICKS_CREATE_A_QMR',
    USER_CHECKS_A_VIN = 'USER_CHECKS_A_VIN',
    USER_CREATES_DRAFT_QMR = 'USER_CREATES_DRAFT_QMR',
    USER_CLICKS_SUBMIT_QMR = 'USER_CLICKS_SUBMIT_QMR',
    USER_SUCCESSFULLY_SUBMITS_QMR = 'USER_SUCCESSFULLY_SUBMITS_QMR',
    USER_GIVES_FEEDBACK = 'USER_GIVES_FEEDBACK',
    USER_CHANGES_QMR_STATUS = 'USER_CHANGES_QMR_STATUS',
    USER_REPLIES_TO_FEEDBACK = 'USER_REPLIES_TO_FEEDBACK',
    USER_REQUESTS_FEEDBACK = 'USER_REQUESTS_FEEDBACK',
    USER_CHANGED_SCREENS = 'USER_CHANGED_SCREENS',
    USER_CHANGED_PAGE = 'USER_CHANGED_PAGE',
    USER_OPENS_FAILCODE_MODAL = 'USER_OPENS_FAILCODE_MODAL',
    USER_CLOSES_FAILCODE_MODAL = 'USER_CLOSES_FAILCODE_MODAL',
    USER_SUCCESSFULLY_ADDS_FAILCODE = 'USER_SUCCESSFULLY_ADDS_FAILCODE',
    USER_STARTS_ATTACHMENT = 'USER_STARTS_ATTACHMENT',
    USER_STARTS_ADVANCED_SEARCH = 'USER_STARTS_ADVANCED_SEARCH',
    USER_SAVES_ADVANCED_SEARCH = 'USER_SAVES_ADVANCED_SEARCH',
    ADVANCED_SEARCH_LOADED = 'ADVANCED_SEARCH_LOADED',
    USER_SEARCHES_BY_QUICKSEARCH = 'USER_SEARCHES_BY_QUICKSEARCH',
    USER_SEARCHES_BY_TEXT = 'USER_SEARCHES_BY_TEXT',
    USER_CLICKS_CREATE_INFOTAINMENT_CASE = 'USER_CLICKS_CREATE_INFOTAINMENT_CASE',
    USER_CLICKS_VIEW_OPEN_INFOTAINMENT_CASE = 'USER_CLICKS_VIEW_OPEN_INFOTAINMENT_CASE',
    USER_CLICKS_CLOSE_INFOTAINMENT_CASE = 'USER_CLICKS_CLOSE_INFOTAINMENT_CASE',

    USER_CLICKS_CREATE_A_TECHLINE_CASE = 'USER_CLICKS_CREATE_A_TECHLINE_CASE',
    USER_SELECTS_RETAILER = 'USER_SELECTS_RETAILER',
    USER_OPENS_EXISTING_CASE = 'USER_OPENS_EXISTING_CASE',
    USER_CREATES_DRAFT_TECHLINE_CASE = 'USER_CREATES_DRAFT_TECHLINE_CASE',
    USER_CLICKS_SUBMIT_TECHLINE_CASE = 'USER_CLICKS_SUBMIT_TECHLINE_CASE',
    USER_SUCCESSFULLY_SUBMITS_TECHLINE_CASE = 'USER_SUCCESSFULLY_SUBMITS_TECHLINE_CASE',
    USER_ADDS_DTC = 'USER_ADDS_DTC',
    USER_STARTS_ADDING_FAILCODE = 'USER_STARTS_ADDING_FAILCODE',
    USER_ADDS_WORKSHEET = 'USER_ADDS_WORKSHEET',
    USER_DELETES_WORKSHEET_DRAFT = 'USER_DELETES_WORKSHEET_DRAFT',
    USER_SUBMITS_WORKSHEET = 'USER_SUBMITS_WORKSHEET',
    USER_VIEWS_WORKSHEET = 'USER_VIEWS_WORKSHEET',
    USER_STARTS_ATTACHMENT_FLOW = 'USER_STARTS_ATTACHMENT_FLOW',
    USER_SUCCESSFULLY_UPLOADS_ATTACHMENT = 'USER_SUCCESSFULLY_UPLOADS_ATTACHMENT',
    USER_SEARCHES_TECHLINE_CASES = 'USER_SEARCHES_TECHLINE_CASES',
    USER_ADDS_COMMENT = 'USER_ADDS_COMMENT',
    USER_STARTS_ATTACHMENT_COMMENT = 'USER_STARTS_ATTACHMENT_COMMENT',
    USER_SUCCESSFULLY_UPLOADS_ATTACHMENT_COMMENT = 'USER_SUCCESSFULLY_UPLOADS_ATTACHMENT_COMMENT',
    USER_STARTS_CLOSE_CASE_FLOW = 'USER_STARTS_CLOSE_CASE_FLOW',
    USER_SUCCESSFULLY_CLOSES_TECHLINE_CASE = 'USER_SUCCESSFULLY_CLOSES_TECHLINE_CASE',
    USER_DELETES_TECHLINE_DRAFT = 'USER_DELETES_TECHLINE_DRAFT',
    USER_CLICKS_NOTIFICATION_LINK = 'USER_CLICKS_NOTIFICATION_LINK',
    USER_CLICKS_TO_LOGOFF = 'USER_CLICKS_TO_LOGOFF',
    USER_SUCCESSFULLY_LOGS_OFF = 'USER_SUCCESSFULLY_LOGS_OFF',

    QMRDASH_USER_VISITED_DSQM_DASHBOARD = 'QMRDASH_USER_VISITED_DSQM_DASHBOARD',
    QMRDASH_USER_CHANGED_PERIOD = 'QMRDASH_USER_CHANGED_PERIOD',
    QMRDASH_USER_CHANGED_PERIOD_COMPARED_TO = 'QMRDASH_USER_CHANGED_PERIOD_COMPARED_TO',
    QMRDASH_USER_CLICKED_HAVE_YOU_SEEN = 'QMRDASH_USER_CLICKED_HAVE_YOU_SEEN',
    QMRDASH_USER_CLICKED_HELPFUL_LINK = 'QMRDASH_USER_CLICKED_HELPFUL_LINK',
    QMRDASH_USER_CLICKED_COUNTER_MEASURES_OF_THE_MONTH = 'QMRDASH_USER_CLICKED_COUNTER_MEASURES_OF_THE_MONTH',
    QMRDASH_USER_CLICKED_TO_REVIEW_QMR = 'QMRDASH_USER_CLICKED_TO_REVIEW_QMR',
    QMRDASH_USER_CLICKED_PENDING_QMR = 'QMRDASH_USER_CLICKED_PENDING_QMR',

    USER_CLICKS_ON_TECHLINE_VIN_SCAN = 'USER_CLICKS_ON_TECHLINE_VIN_SCAN',
    USER_CLICKS_ON_QMR_VIN_SCAN = 'USER_CLICKS_ON_QMR_VIN_SCAN',
    VIN_SCAN_SUCCESSFUL = 'VIN_SCAN_SUCCESSFUL',
    VIN_SCAN_FAILD = 'VIN_SCAN_FAILD',
    USER_ENTERED_VIN_TECHLINE = 'USER_ENTERED_VIN_TECHLINE',
    USER_ENTERED_VIN_QMR = 'USER_ENTERED_VIN_QMR',
}

export class AnalyticsService {
    private configService: ConfigService;
    private analyticsInstance: amplitude.AmplitudeClient;
    private user: Account | undefined;

    constructor(configService: ConfigService) {
        this.configService = configService;
        this.analyticsInstance = amplitude.getInstance();
        this.analyticsInstance.init(configService.get(Constants.Env.amplitudeApiKey) || '', '', {
            platform: Platform.OS,
        });
    }

    public setUser(user: Account) {
        this.user = user;
        this.analyticsInstance.setUserId(this.user.soaUsername);
        this.analyticsInstance.setUserProperties({
            username: this.user?.soaUsername ? this.user.soaUsername : 'N/A',
            userRole: this.user?.roleIdDescription ? this.user.roleIdDescription : 'N/A',
            userBaseRoles: this.user?.baseRolesDescription ? this.user.baseRolesDescription : 'N/A',
            userAddOnRoles: this.user?.addOnRolesDescription ? this.user.addOnRolesDescription : 'N/A',
            locations: this.user?.locationsDescription ? this.user.locationsDescription : 'N/A',
        });
    }

    public logEvent(event: ANALYTICS_EVENTS, data?: any, callback?: () => void) {
        const eventConfig = {
            timeStamp: new Date().toString(),
            platform: undefined,
            environment: this.configService.currentEnvName,
            ...data,
        };

        this.analyticsInstance.logEvent(event, eventConfig, () => {
            if (this.configService.buildDebugEnabled) {
                console.group('Analytics Event Fired');
                console.log('event:', event);
                console.log('config', eventConfig);
                console.groupEnd();
            }

            if (callback) {
                callback();
            }
        });
    }
}
