import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, TextBadge, Button, Typography, LoadingCircle } from '@packages/ui/shared';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, SORT_DIRECTION } from '@web/components/table';
import { analyticsService, qmrsService } from '@web/services/singletons';
import { LayoutAnimation } from 'react-native';
import { useAbortController } from '@packages/core/http';
import { View, StyleSheet } from 'react-native';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { Empty } from '@web/components/empty/empty';
import useQuery from '@web/core/hooks/use-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from '@packages/contexts/auth';
import { getQmrRoleReview } from '@packages/contexts/qmrs';
import { DsqmReviewStatus } from '@web/qmr/components/qmr-table';
import { useVinSessionDispatch, useVinSessionState } from '../context/vin-sessions.hooks';
import { VIN } from '../types';
import { Qmr } from '@packages/models/api';
import { vinSessionActions } from '../context/vin-sessions.state';

const useStyles = createUseStyles({
    loaderCircleOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    caseNumberLink: {
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    noResultsOuter: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loadingCircleContainer: {
        minHeight: '40vh',
    },
    tableOuter: ({ isLoading }: { isLoading: boolean }) => ({
        height: '100%',
        minHeight: 'calc(100vh - 550px)',
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
    }),
    bannerButtonOuter: {
        alignItems: 'flex-start',
    },
});

const styles = StyleSheet.create({
    bannerButtonOuter: {
        alignItems: 'flex-start',
    },
});

export const QmrReports: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles({ isLoading });
    let content: JSX.Element | JSX.Element[];
    const tableHeadRef = useRef<HTMLTableSectionElement>(null);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const queryParams = useQuery();
    const sortOrder = queryParams.get('sortOrder');
    const sortDirection = queryParams.get('sortDir');
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname, search } = useLocation();
    const { account } = useAuthState();
    const { abortSignalRef } = useAbortController();
    const { selectedVin } = useVinSessionState();
    const [displayCases, setDisplayCases] = useState<Qmr[]>([]);
    const dispatch = useVinSessionDispatch();

    const [isDsqmStatusPresent, setIsDsqmStatusPresent] = useState<boolean>(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const hasDsqmStatusId = searchParams.has('dsqmStatusId');
        setIsDsqmStatusPresent(hasDsqmStatusId);
    }, [location.search]);

    useEffect(() => {
        if (selectedVin) {
            fetchAndSetQmrListItems(selectedVin);
        }
    }, [selectedVin]);

    const fetchAndSetQmrListItems = useCallback(
        (selectedVin: VIN) => {
            const params =
                selectedVin && new URLSearchParams({ page: '0', vin: selectedVin.vinRecord.vin, size: '10' });

            return qmrsService
                .fetchQmrsList({
                    queryString: params ? params.toString() : '',
                    ignoreCache: true,
                    signal: abortSignalRef.current,
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }
                    setDisplayCases(response.data.qmrs);
                    dispatch(
                        vinSessionActions.setVinQmrsCount({
                            vinQmrsCount: response.data.totalCount,
                        })
                    );
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                });
        },
        [abortSignalRef]
    );

    const onSort = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handleSort = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            onSort(sortBy, sortDirection);
        },
        [onSort]
    );

    const trackAnalytics = (qmrId: string) => {
        analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_TO_REVIEW_QMR, {
            QMR_ID: qmrId,
        });
    };

    const tableHeaderRowRenderer = () => {
        return (
            <TableRow>
                <TableHeader
                    sortable
                    sortDirection={
                        sortOrder === 'UPDATED' ? (sortDirection ? (sortDirection as SORT_DIRECTION) : null) : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('UPDATED', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        Updated
                    </Typography>
                </TableHeader>
                <TableHeader
                    sortDirection={
                        sortOrder === 'QMR_ID' ? (sortDirection ? (sortDirection as SORT_DIRECTION) : null) : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('QMR_ID', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        QMR ID
                    </Typography>
                </TableHeader>
                <TableHeader
                    sortDirection={
                        sortOrder === 'VIN' ? (sortDirection ? (sortDirection as SORT_DIRECTION) : null) : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('VIN', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        VIN
                    </Typography>
                </TableHeader>
                <TableHeader
                    sortable
                    sortDirection={
                        sortOrder === 'QMR_STATUS'
                            ? sortDirection
                                ? (sortDirection as SORT_DIRECTION)
                                : null
                            : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('QMR_STATUS', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        {'QMR Status'}
                    </Typography>
                </TableHeader>
                {isDsqmStatusPresent && (
                    <TableHeader
                        sortable
                        sortDirection={
                            sortOrder === 'QMR_STATUS'
                                ? sortDirection
                                    ? (sortDirection as SORT_DIRECTION)
                                    : null
                                : undefined
                        }
                        onSort={(_event, sortDirection) => {
                            handleSort('QMR_STATUS', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Feedback Status
                        </Typography>
                    </TableHeader>
                )}
                {account?.systemCapabilities.viewQmrRoleReviewOnList && (
                    <TableHeader
                        sortDirection={
                            sortOrder === 'ROLE'
                                ? sortDirection
                                    ? (sortDirection as SORT_DIRECTION)
                                    : null
                                : undefined
                        }
                        onSort={(_event, sortDirection) => {
                            handleSort('ROLE', sortDirection);
                        }}
                    >
                        <Typography variant="label" numberOfLines={1}>
                            Role Review
                        </Typography>
                    </TableHeader>
                )}
                <TableHeader
                    sortable
                    sortDirection={
                        sortOrder === 'YEAR' ? (sortDirection ? (sortDirection as SORT_DIRECTION) : null) : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('YEAR', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        Year
                    </Typography>
                </TableHeader>
                <TableHeader
                    sortable
                    sortDirection={
                        sortOrder === 'CARLINE' ? (sortDirection ? (sortDirection as SORT_DIRECTION) : null) : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('CARLINE', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        Carline
                    </Typography>
                </TableHeader>
                <TableHeader
                    sortable
                    sortDirection={
                        sortOrder === 'FAIL_CODE'
                            ? sortDirection
                                ? (sortDirection as SORT_DIRECTION)
                                : null
                            : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('FAIL_CODE', sortDirection);
                    }}
                >
                    <Typography variant="label" numberOfLines={1}>
                        Fail Code
                    </Typography>
                </TableHeader>
                <TableHeader
                    sortable
                    sortDirection={
                        sortOrder === 'RETAILER'
                            ? sortDirection
                                ? (sortDirection as SORT_DIRECTION)
                                : null
                            : undefined
                    }
                    onSort={(_event, sortDirection) => {
                        handleSort('RETAILER', sortDirection);
                    }}
                    hideBorder
                    colspan={2}
                >
                    <Typography variant="label" numberOfLines={1}>
                        Retailer
                    </Typography>
                </TableHeader>
            </TableRow>
        );
    };

    const renderLoader = () => (
        <TableRow>
            <TableCell className={classes.loadingCircleContainer}>
                <div className={classes.loaderCircleOuter}>
                    <LoadingCircle size={64} />
                </div>
            </TableCell>
        </TableRow>
    );

    const renderRows = () =>
        displayCases.map((qmr: any) => (
            <React.Fragment>
                <TableRow>
                    <TableCell classNameOuter="align-middle">
                        <Typography numberOfLines={1}>{qmr.lastEditedTimestampDescription}</Typography>
                    </TableCell>
                    <TableCell classNameOuter="align-middle">
                        <Typography numberOfLines={1}>
                            <a
                                href={`/qmrs/${qmr.displayIdentifier}${search}`}
                                onClick={() => trackAnalytics(qmr.displayIdentifier)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {qmr.displayIdentifier}
                            </a>
                        </Typography>
                        <div className="d-flex align-items-center">
                            <div className="mr-1" style={{ transform: 'rotate(90deg)' }}>
                                <Icon name="paperclip" size={14} />
                            </div>
                            <Typography variant="caption" color="textDarkSecondary">
                                {qmr.assets?.length} attachments
                            </Typography>
                        </div>
                    </TableCell>
                    <TableCell classNameOuter="align-middle">
                        <Typography numberOfLines={1}> {qmr.vin?.slice(-8)}</Typography>
                    </TableCell>
                    <TableCell classNameOuter="align-middle" width={215}>
                        <div className="d-inline-flex">
                            <TextBadge
                                icon={
                                    <Icon
                                        size={16}
                                        name={getQmrRoleReview(qmr).qa.iconName}
                                        color={getQmrRoleReview(qmr).qa.iconColor}
                                    />
                                }
                                variant={getQmrRoleReview(qmr).qa.badgeVariant}
                            >
                                {qmr.qmrStatus.description}
                            </TextBadge>
                        </div>
                    </TableCell>
                    {isDsqmStatusPresent && (
                        <TableCell classNameOuter="align-middle" width={215}>
                            <div className="d-inline-flex">
                                <TextBadge
                                    icon={
                                        <Icon
                                            size={16}
                                            name={getQmrRoleReview(qmr).dsqm.iconName}
                                            color={getQmrRoleReview(qmr).dsqm.iconColor}
                                        />
                                    }
                                    variant={getQmrRoleReview(qmr).dsqm.badgeVariant}
                                >
                                    {
                                        DsqmReviewStatus[
                                            qmr.dsqmReviewStatus.dsqmReviewStatusId as keyof typeof DsqmReviewStatus
                                        ]
                                    }
                                </TextBadge>
                            </div>
                        </TableCell>
                    )}
                    {account?.systemCapabilities.viewQmrRoleReviewOnList && (
                        <TableCell classNameOuter="align-middle">
                            <div className="d-flex align-items-center">
                                <Icon
                                    size={16}
                                    name={getQmrRoleReview(qmr).dqsm.iconName}
                                    color={getQmrRoleReview(qmr).dqsm.iconColor}
                                />
                                <span className="ml-1">
                                    <Typography variant="caption">DSQM</Typography>
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <Icon
                                    size={16}
                                    name={getQmrRoleReview(qmr).qa.iconName}
                                    color={getQmrRoleReview(qmr).qa.iconColor}
                                />
                                <span className="ml-1">
                                    <Typography variant="caption">QA</Typography>
                                </span>
                            </div>
                        </TableCell>
                    )}
                    <TableCell classNameOuter="align-middle">
                        <Typography numberOfLines={1}>{qmr.modelYear}</Typography>
                    </TableCell>
                    <TableCell classNameOuter="align-middle">
                        <Typography numberOfLines={1}>{qmr.carlineName}</Typography>
                    </TableCell>
                    <TableCell classNameOuter="align-middle">
                        {qmr.failCodeApproved === false && (
                            <Typography numberOfLines={1} color="graySix">
                                Pending
                            </Typography>
                        )}

                        {qmr.failCodeApproved !== false && (
                            <>
                                <div className={qmr.failCodeSectionName ? 'mb-1' : 'mb-0'}>
                                    <Typography numberOfLines={1} color="blueOne">
                                        {qmr.failCode}
                                    </Typography>
                                </div>
                                {qmr.failCodeSectionName && (
                                    <div className={qmr.failCodeSubsectionName ? 'mb-1' : 'mb-0'}>
                                        <Typography numberOfLines={1} variant="caption" color="graySix">
                                            {qmr.failCodeSectionName}
                                        </Typography>
                                    </div>
                                )}
                                {qmr.failCodeSubsectionName && (
                                    <div>
                                        <Typography numberOfLines={1} variant="caption" color="graySix">
                                            {qmr.failCodeSubsectionName}
                                        </Typography>
                                    </div>
                                )}
                            </>
                        )}
                    </TableCell>
                    <TableCell classNameOuter="align-middle">
                        <Typography numberOfLines={1}>{qmr.retailer?.name}</Typography>
                        <div>
                            <Typography variant="caption" color="textDarkSecondary">
                                &#35;{qmr.retailer?.code}
                            </Typography>
                        </div>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        ));

    const renderEmptyState = () => (
        <TableRow>
            <TableCell>
                <div className={classes.noResultsOuter}>
                    <Empty
                        description={`There is no QMR case created for this car`}
                        iconFlag={true}
                        descriptionStyle={{ fontWeight: '400', fontSize: 14, lineHeight: 20 }}
                        className="mt-10"
                    />
                </div>
            </TableCell>
        </TableRow>
    );

    if (isLoading) {
        content = renderLoader();
    } else if (displayCases?.length) {
        content = renderRows();
    } else {
        content = renderEmptyState();
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className="mb-4">{`QMR Reports`}</h3>
                <View style={styles.bannerButtonOuter}>
                    <Button
                        variant="info"
                        size="small"
                        iconRight={<Icon size={16} name="chevron-right" color="blueOne" />}
                        onPress={() => navigate(`/vin/${selectedVin?.vinRecord.vin}/qmrs`)}
                    >
                        {'See all'}
                    </Button>
                </View>
            </div>

            <Table className={classes.tableOuter}>
                <TableHead ref={tableHeadRef}>{tableHeaderRowRenderer()}</TableHead>
                <TableBody ref={tableBodyRef}>{content}</TableBody>
            </Table>
        </div>
    );
};
