import React, { FC, useCallback, useState, useRef } from 'react';
import { ActivityIndicator } from 'react-native';
import { Row, Col, Form, FormControlProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import { uniq, split } from 'lodash';

import { useAbortController } from '@packages/core/http';
import { AssetType, Asset, AssetProcessingState, AssetMediaType } from '@packages/models/api';
import {
    AssetUploadInfo,
    useAssetUploadsDispatch,
    assetUploadsActions,
    AssetUploadTaskStatus,
    updateAssetProcessingStatus,
} from '@packages/contexts/asset-uploads';
import {
    useQmrState,
    useQmrDispatch,
    useQmrAssetPolling,
    getQmrAssetDetails,
    QmrAssetDetails,
    deleteQmrAssets,
    qmrActions,
    updateQmrRestrictedAssetIds,
} from '@packages/contexts/qmrs';

import colors from '@packages/core/styles/colors';
import { getMediaUrl } from '@packages/core/utils';
import { QmrAssetListItem } from '@packages/ui/qmr';

import AssetEditorModal from '@web/qmr/components/asset-editor-modal';
import { assetUploadsService, httpClient, qmrsService } from '@web/services/singletons';
import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';
import ConfirmDeleteAssetModal from './confirm-delete-asset-modal';
import { usePushNotifications } from '@web/core/hooks';
import { getPopoverContentLocation } from '../../utils/popover-content-location';

const useAttachmentsStyles = createUseStyles({
    dropZoneArea: {
        height: 80,
        marginRight: 24,
        paddingLeft: 24,
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        border: `2px dashed ${colors.grayThree}`,
    },
    attachmentThumbnail: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    attachmentNameUnderline: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    assetPopover: {
        minWidth: 175,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
    },
    assetPopoverSection: {
        padding: '4px 0',
    },
    downloadAssetBtn: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    lockOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'rgba(0,0,0,0.65)',
    },
});

interface AttachmentsProps {
    toggle: () => void;
}

const Attachments = (props: AttachmentsProps) => {
    useQmrAssetPolling(assetUploadsService);
    const { toggle } = props;
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const { abortSignalRef } = useAbortController();
    const classes = useAttachmentsStyles();
    const assetUploadsDispatch = useAssetUploadsDispatch();
    const qmrDispatch = useQmrDispatch();

    const qmrAssetDetails = getQmrAssetDetails(qmr);

    const [viewOnly, setViewOnly] = useState(true);
    const [editorAssetIndex, setEditorAssetIndex] = useState(-1);
    const [assetProgress, setAssetProgress] = useState<{
        [assetId: string]: {
            uploadId: string;
            status: string;
            progress: number;
        };
    }>({});
    const [popoverId, setPopoverId] = useState<string | null>(null);
    const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(false);
    const confirmDeleteAssetCallbackRef = useRef<() => Promise<any>>();

    const pushNotificationHandler = useCallback(
        (notification: any) => {
            if (!qmr || !Array.isArray(qmr.assets) || qmr.assets.length === 0) {
                return;
            }

            if (notification.assetChangedTypeId === 'ASSET_PROCESSING_STATE') {
                const assetBelongsToQmr =
                    qmr.assets && qmr.assets.findIndex((a) => a.assetId === notification.assetId) > -1;

                if (!assetBelongsToQmr) {
                    return;
                }

                assetUploadsService
                    .getAsset({
                        assetId: notification.assetId,
                        ignoreCache: true,
                        signal: abortSignalRef.current,
                    })
                    .then((response) => {
                        if (!response.success && response.aborted) {
                            return;
                        } else if (!response.success) {
                            throw response.data;
                        }

                        qmrDispatch(qmrActions.updateAsset({ asset: response.data.asset }));
                    });
            }
        },
        [abortSignalRef, qmr, qmrDispatch]
    );

    usePushNotifications(pushNotificationHandler);

    const onFilesDrop = useCallback(
        async (files: File[]) => {
            if (!qmr) {
                return;
            }
            let inValidFileName = false;
            files.map((file) => {
                let splitFileName = file.name && split(file.name, '.');
                let fileExtn = splitFileName[1];
                if (!splitFileName[0]) {
                    inValidFileName = true;
                    alert('The file name should not be empty');
                } else if (fileExtn === '' || fileExtn === file.name) {
                    inValidFileName = true;
                    alert('Please add a file extension to the file name.');
                }
                return inValidFileName;
            });
            if (!inValidFileName) {
                const assetUploads: AssetUploadInfo[] = files.map((file) => {
                    let assetTypeId: AssetType;
                    // The browser doesn't return file type of `heic` files on windows
                    // so manually check file extension and set assetTypeId accordingly
                    if (!file.type) {
                        assetTypeId = file.name.toLowerCase().endsWith('.heic') ? AssetType.Image : AssetType.File;
                    } else {
                        assetTypeId = file.type.includes('video')
                            ? AssetType.Video
                            : file.type.includes('image')
                              ? AssetType.Image
                              : AssetType.File;
                    }
                    return {
                        assetTypeId,
                        assetDisposition: {
                            assetDispositionId: 'QMR',
                            qmrId: qmr.qmrId,
                        },
                        name: file.name,
                        assetUri: file,
                        sizeInBytes: file.size,
                        contentType: file.type,
                        eventHandlers: {
                            onCompleted: (uploadId: string, asset: Asset) => {
                                const { [asset.assetId]: removed, ...rest } = assetProgress;
                                setAssetProgress({ ...rest });

                                assetUploadsDispatch(
                                    assetUploadsActions.updateTask({
                                        uploadId,
                                        status: AssetUploadTaskStatus.Completed,
                                    })
                                );

                                qmrDispatch(
                                    qmrActions.updateAsset({
                                        asset: { ...asset, assetProcessingStateId: AssetProcessingState.Processing },
                                    })
                                );
                            },
                            onProgress: (uploadId: string, asset: Asset, progress: number) => {
                                httpClient.refreshToken();

                                setAssetProgress({
                                    ...assetProgress,
                                    [asset.assetId]: {
                                        ...assetProgress[asset.assetId],
                                        progress,
                                    },
                                });
                            },
                            onError: (uploadId: string, asset: Asset, error: string) => {
                                updateAssetProcessingStatus({
                                    uploadId,
                                    assetId: asset.assetId,
                                    assetProcessingStateId: AssetProcessingState.Error,
                                    assetUploadsService,
                                    assetUploadsDispatch,
                                    signal: abortSignalRef.current,
                                })
                                    .then((updatedAsset) => {
                                        if (!updatedAsset) {
                                            return;
                                        }

                                        // error from AWS-S3
                                        alert(error);
                                    })
                                    .catch((e) => {
                                        // API error when updating error state
                                        alert(e.message);
                                    });
                            },
                        },
                    };
                });

                assetUploadsService.queueUploadTasks(assetUploads).then((uploadTasks) => {
                    if (uploadTasks) {
                        uploadTasks.forEach((task, index) => {
                            qmrDispatch(
                                qmrActions.updateAsset({
                                    asset: { ...task.asset, assetProcessingStateId: AssetProcessingState.Uploading },
                                })
                            );
                        });

                        assetUploadsDispatch(assetUploadsActions.addUploadTasks({ uploadTasks }));

                        setAssetProgress(
                            uploadTasks.reduce((assetProgress, task) => {
                                return {
                                    ...assetProgress,
                                    [task.asset.assetId]: {
                                        uploadId: task.uploadId,
                                        status: task.status,
                                        progress: 0,
                                    },
                                };
                            }, {})
                        );
                    }
                });
            }
        },
        [abortSignalRef, assetProgress, assetUploadsDispatch, qmr, qmrDispatch]
    );

    const {
        getRootProps,
        getInputProps,
        open: openFileInput,
    } = useDropzone({
        onDrop: onFilesDrop,
        noClick: true,
        noKeyboard: true,
    });

    const dropZoneRootProps = getRootProps();
    const fileInputProps = getInputProps() as FormControlProps;

    const itemOptionsRenderer = (assetDetail: QmrAssetDetails, index: number) => () => {
        return (
            <Popover
                isOpen={popoverId === assetDetail.assetId}
                positions={['bottom', 'top']}
                onClickOutside={() => setPopoverId(null)}
                contentLocation={({ childRect, popoverRect, position }) => {
                    return getPopoverContentLocation(childRect, popoverRect, position, 4);
                }}
                content={
                    <div className={classes.assetPopover}>
                        <div className={classes.assetPopoverSection}>
                            <Button
                                alignTitle="left"
                                variant="ghost-gray"
                                onPress={() => {
                                    setViewOnly(true);
                                    setEditorAssetIndex(index);
                                    setPopoverId(null);
                                }}
                            >
                                <>
                                    <Icon name="eye" size={16} />
                                    <Typography style={{ paddingLeft: 12 }} variant="label">
                                        {t('buttons:view', 'View')}
                                    </Typography>
                                </>
                            </Button>

                            {assetDetail.assetProcessingStateId === AssetProcessingState.Complete &&
                                qmr?.capabilities.editMediaOnQmr && (
                                    <Button
                                        alignTitle="left"
                                        variant="ghost-gray"
                                        onPress={() => {
                                            setViewOnly(false);
                                            setEditorAssetIndex(index);
                                            setPopoverId(null);
                                        }}
                                    >
                                        <>
                                            <Icon name="pencil" size={16} />
                                            <Typography style={{ paddingLeft: 12 }} variant="label">
                                                {t('buttons:edit', 'Edit')}
                                            </Typography>
                                        </>
                                    </Button>
                                )}

                            {qmr?.capabilities.downloadAttachmentsOnQmr && (
                                <a
                                    href={getMediaUrl(assetDetail.media)}
                                    download={assetDetail.name}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={classes.downloadAssetBtn}
                                >
                                    <Button
                                        alignTitle="left"
                                        variant="ghost-gray"
                                        onPress={() => {
                                            setPopoverId(null);
                                        }}
                                    >
                                        <>
                                            <Icon name="download" size={16} />
                                            <Typography style={{ paddingLeft: 12 }} variant="label">
                                                {t('buttons:download', 'Download')}
                                            </Typography>
                                        </>
                                    </Button>
                                </a>
                            )}

                            {qmr?.capabilities.markRestrictedMediaOnQmr && (
                                <Button
                                    alignTitle="left"
                                    variant="ghost-gray"
                                    onPress={() => {
                                        setPopoverId(null);
                                        let restrictedAssetIds = qmr?.restrictedAssetIds || [];

                                        if (assetDetail.isRestricted) {
                                            restrictedAssetIds = restrictedAssetIds.filter(
                                                (id) => id !== assetDetail.assetId
                                            );
                                        } else {
                                            restrictedAssetIds = uniq([...restrictedAssetIds, assetDetail.assetId]);
                                        }

                                        updateQmrRestrictedAssetIds({
                                            qmrId: qmr.qmrId,
                                            restrictedAssetIds,
                                            qmrDispatch,
                                            qmrsService,
                                        }).catch((e) => {
                                            alert(e.message);
                                        });
                                    }}
                                >
                                    <>
                                        <Icon name="lock-closed" size={16} />
                                        <Typography style={{ paddingLeft: 12 }} variant="label">
                                            {assetDetail.isRestricted
                                                ? t('buttons:unrestrictMedia', 'Mark as unrestricted')
                                                : t('buttons:restrictMedia', 'Mark as restricted')}
                                        </Typography>
                                    </>
                                </Button>
                            )}
                        </div>

                        <hr />

                        {qmr?.capabilities.deleteAssetFromQmr && (
                            <div className={classes.assetPopoverSection}>
                                <Button
                                    alignTitle="left"
                                    variant="ghost-gray"
                                    onPress={() => {
                                        setPopoverId(null);
                                        setShowDeleteAssetModal(true);
                                        confirmDeleteAssetCallbackRef.current = () => {
                                            if (!qmr) {
                                                return Promise.resolve();
                                            }

                                            return deleteQmrAssets({
                                                qmrId: qmr.qmrId,
                                                assetIds: [assetDetail.assetId],
                                                qmrsService,
                                                qmrDispatch,
                                                signal: abortSignalRef.current,
                                            }).catch((error) => {
                                                alert(error.message);
                                            });
                                        };
                                    }}
                                >
                                    <>
                                        <Icon name="trash" size={16} color="redOne" />
                                        <Typography style={{ paddingLeft: 12 }} variant="label" color="redOne">
                                            {t('buttons:delete', 'Delete')}
                                        </Typography>
                                    </>
                                </Button>
                            </div>
                        )}
                    </div>
                }
            >
                <div>
                    <Button
                        active={popoverId === assetDetail.assetId}
                        variant="ghost-blue"
                        onPress={() => setPopoverId(popoverId === assetDetail.assetId ? null : assetDetail.assetId)}
                    >
                        <Icon name="more-dots-vertical" />
                    </Button>
                </div>
            </Popover>
        );
    };

    return (
        <>
            {qmr?.capabilities.deleteAssetFromQmr && (
                <ConfirmDeleteAssetModal
                    show={showDeleteAssetModal}
                    onConfirm={() => {
                        if (confirmDeleteAssetCallbackRef.current) {
                            confirmDeleteAssetCallbackRef.current().finally(() => {
                                setShowDeleteAssetModal(false);
                            });
                        }
                    }}
                    onHide={() => {
                        setShowDeleteAssetModal(false);
                    }}
                />
            )}
            {editorAssetIndex > -1 && qmr?.capabilities.viewMediaOnQmr && (
                <AssetEditorModal
                    viewOnly={viewOnly}
                    toggleEdit={() => {
                        setViewOnly(!viewOnly);
                    }}
                    assetIndex={editorAssetIndex}
                    onClose={() => {
                        setEditorAssetIndex(-1);
                    }}
                    toggle={toggle}
                />
            )}

            <Row>
                <Col>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <h4 className="mb-0">{t('qmr:sections.media.title')}</h4>
                        <p className="mb-0 text-muted">
                            {t('qmr:sections.media.description', {
                                count: qmr?.assets?.length,
                                totalSize:
                                    qmr?.assets
                                        ?.reduce((acc, asset) => {
                                            const originalMedia = asset.media.find(
                                                (m) =>
                                                    ((m as any).assetImageTypeId || (m as any).assetVideoTypeId) ===
                                                    AssetMediaType.Original
                                            );

                                            return (acc += (originalMedia?.sizeInBytes ?? 0) / 1000);
                                        }, 0)
                                        .toFixed(1) + 'KB',
                            })}
                        </p>
                    </div>
                </Col>
            </Row>

            {qmr?.capabilities.attachAssetToQmr && qmr?.capabilities.editQmr && (
                <Row>
                    <Col>
                        <div
                            className="d-flex align-items-center justify-content-start flex-wrap"
                            {...dropZoneRootProps}
                        >
                            <div className={classNames('mt-4 mb-0', classes.dropZoneArea)}>
                                {t('qmr:sections.media.instructions')}
                                &nbsp;
                                <Button
                                    variant="link"
                                    onPress={() => {
                                        openFileInput();
                                    }}
                                    title={t('qmr:sections.media.uploadLink', 'upload')}
                                />
                            </div>

                            <Form.Group
                                controlId="attachments"
                                className="mt-4 mb-0"
                                onClick={() => {
                                    openFileInput();
                                }}
                            >
                                <Form.Control as="input" {...fileInputProps} />
                                <Form.Label />
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
            )}

            {qmrAssetDetails.map((assetDetail, index) => {
                return (
                    <QmrAssetListItem
                        key={assetDetail.assetId}
                        assetTypeId={assetDetail.assetTypeId}
                        title={
                            <div
                                onClick={() => {
                                    setViewOnly(true);
                                    setEditorAssetIndex(index);
                                }}
                                className={classes.attachmentNameUnderline}
                            >
                                {assetDetail.name}
                            </div>
                        }
                        description={assetDetail.note}
                        createdTimestampDescription={assetDetail.createdTimestampDescription}
                        progress={assetProgress[assetDetail.assetId]?.progress}
                        moreOptionsRenderer={itemOptionsRenderer(assetDetail, index)}
                        isRestricted={assetDetail.isRestricted}
                        isAdditionalUpload={assetDetail.isAdditionalUpload}
                        thumbnailRenderer={(size) => {
                            const showThumbnailImage = !assetDetail.thumbnailMedia?.iconName;
                            const thumbnailSrc = showThumbnailImage ? assetDetail.thumbnailMedia?.uri : undefined;
                            const backgroundImage = showThumbnailImage
                                ? thumbnailSrc && `url(${thumbnailSrc})`
                                : undefined;

                            return (
                                <div
                                    style={{
                                        backgroundImage,
                                        ...size,
                                    }}
                                    className={classes.attachmentThumbnail}
                                    onClick={() => {
                                        setEditorAssetIndex(index);
                                        setViewOnly(true);
                                    }}
                                >
                                    {showThumbnailImage
                                        ? !thumbnailSrc && <ActivityIndicator color={colors.blueOne} size="large" />
                                        : !assetDetail.isRestricted && (
                                              <Icon
                                                  name={(assetDetail.thumbnailMedia?.iconName || '') as StsIconName}
                                              />
                                          )}
                                    {assetDetail.isRestricted && (
                                        <div className={classes.lockOverlay}>
                                            <Icon name="lock-closed" color="white" />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                        onCancelUpload={() => {
                            console.log('cancel');
                        }}
                    />
                );
            })}
        </>
    );
};

export default Attachments;
