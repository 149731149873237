import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { Typography, Button } from '@packages/ui/shared';
export interface SectionHeaderProps {
    title: string;
    description: string;
    onPress: () => void;
    isDisabled: boolean;
    buttonLabel: string;
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textWrapper: {
        flexDirection: 'row',
        gap: 10,
    },
    title: {
        fontSize: 21,
        fontWeight: '600',
    },
    description: {
        fontSize: 15,
        fontWeight: '400',
    },
});

export const SectionHeader: FC<SectionHeaderProps> = ({ title, description, onPress, isDisabled, buttonLabel }) => {
    return (
        <View style={styles.container}>
            <View style={styles.textWrapper}>
                <Typography variant="h3" style={styles.title}>
                    {title}
                </Typography>
                <Typography variant="lead" style={styles.description}>
                    {description}
                </Typography>
            </View>
            <Button variant="outlinePrimary" onPress={onPress} title={buttonLabel} disabled={isDisabled} />
        </View>
    );
};
