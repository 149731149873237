import * as React from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { baseDashboardUpdates } from './dashboard.context';

export const baseDashboard: IDashboard = {
    dashboardId: '',
    createdTimestamp: '',
    createdAccountId: '',
    lastUpdatedTimestamp: '',
    lastUpdatedAccountId: '',
    deleted: false,
    bannerMessage: '',
    bannerLink: '',
    bannerHeader: '',
    type: '',
    notificationPopup: false,
    displayOrder: '',
};

export enum DashboardActionsList {
    GET_DASHBOARD = 'GET_DASHBOARD',
    UPDATE_DASHBOARD = 'UPDATE_DASHBOARD',
    DELETE_DASHBOARD = 'DELETE_DASHBOARD',
    CREATE_DASHBOARD = 'CREATE_DASHBOARD',
    GET_DASHBOARD_UPDATE = 'GET_DASHBOARD_UPDATE',
    UPDATE_DASHBOARD_UPDATE = 'UPDATE_DASHBOARD_UPDATE',
    DELETE_DASHBOARD_UPDATE = 'DELETE_DASHBOARD_UPDATE',
    CREATE_DASHBOARD_UPDATE = 'CREATE_DASHBOARD_UPDATE',
    HAS_FETCHED = 'HAS_FETCHED',
    IS_LOADING = 'IS_LOADING',
    HAS_LOADED = 'HAS_LOADED',
    HAS_ERRORS = 'HAS_ERRORS',
}

export interface IDashboard {
    dashboardId: string;
    createdTimestamp: string;
    createdAccountId: string;
    lastUpdatedTimestamp: string;
    lastUpdatedAccountId: string;
    deleted: boolean;
    bannerMessage: string;
    bannerLink: string;
    bannerHeader: string;
    notificationPopup: boolean;
    type?: string;
    buttonText?: string;
    displayOrder: string;
}

export interface IDashboardUpdates {
    dashboardUpdateId: string;
    heading: string;
    description: string;
    link: string;
    deleted: boolean;
    type: string;
    displayOrder: string;
}

export interface IDashboardBanner {
    dashboardId: string;
    deleted: boolean;
    bannerHeader: string;
    bannerMessage: string;
    bannerLink: string;
}

export interface IDashboardProps {
    dashboard: IDashboard;
}

export interface IDashboardUpdateProps {
    dashboardUpdates: IDashboardUpdates[];
}
export interface IDashboardResponse {
    dashboard: {
        dashboard: IDashboard;
        dashboardUpdates: IDashboardUpdates[];
    };
}

export interface IDashboardRequest {
    dashboard: Omit<IDashboard, 'dashboardId'>;
    dashboardUpdates: Omit<IDashboardUpdates, 'dashboardUpdateId'>[];
}

export interface IDashboardUpdateRequest {
    dashboard: Partial<IDashboard>;
}

export interface IDashboardCreateRequest extends IDashboardUpdateRequest {}

export interface IDashboardState {
    dashboard: IDashboard;
    dashboardUpdates: IDashboardUpdates[];
    errors: string[];
    hasFetched: boolean;
    loading: boolean;
}

export const dashboardActions = {
    hasFetched: action(DashboardActionsList.HAS_FETCHED),
    isLoading: action(DashboardActionsList.IS_LOADING),
    hasLoaded: action(DashboardActionsList.HAS_LOADED),
    hasErrors: action(DashboardActionsList.HAS_ERRORS, props<{ errors: string[] }>()),
    getDashboard: action(DashboardActionsList.GET_DASHBOARD, props<IDashboardProps>()),
    updateDashboard: action(DashboardActionsList.UPDATE_DASHBOARD, props<IDashboardProps>()),
    createDashboard: action(DashboardActionsList.CREATE_DASHBOARD, props<IDashboardProps>()),
    deleteDashboard: action(DashboardActionsList.DELETE_DASHBOARD),
    getDashboardUpdate: action(DashboardActionsList.GET_DASHBOARD_UPDATE, props<IDashboardUpdateProps>()),
    updateDashboardUpdate: action(DashboardActionsList.UPDATE_DASHBOARD_UPDATE, props<IDashboardUpdateProps>()),
    createDashboardUpdate: action(DashboardActionsList.CREATE_DASHBOARD_UPDATE, props<IDashboardUpdateProps>()),
    deleteDashboardUpdate: action(DashboardActionsList.DELETE_DASHBOARD_UPDATE, props<{ dashboardUpdateId: string }>()),
};

const actionsUnion = union(...Object.values(dashboardActions));
export type DashboardActions = typeof actionsUnion.actions;

export type DashboardActionDispatcher = React.Dispatch<DashboardActions>;

export const initialState: IDashboardState = {
    dashboard: baseDashboard,
    loading: false,
    hasFetched: false,
    errors: [],
    dashboardUpdates: [],
};

export const dashboardReducer: React.Reducer<IDashboardState, DashboardActions> = reducer(
    initialState,
    on(dashboardActions.hasFetched, (state) => {
        return {
            ...state,
            hasFetched: true,
        };
    }),
    on(dashboardActions.isLoading, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(dashboardActions.hasLoaded, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(dashboardActions.hasErrors, (state, { errors }) => {
        return {
            ...state,
            errors,
        };
    }),
    on(dashboardActions.getDashboard, (state, { dashboard }) => {
        return {
            ...state,
            dashboard,
            errors: [],
        };
    }),
    on(dashboardActions.updateDashboard, (state, { dashboard }) => {
        return {
            ...state,
            dashboard,
            errors: [],
        };
    }),
    on(dashboardActions.createDashboard, (state, { dashboard }) => {
        return {
            ...state,
            dashboard,
            errors: [],
        };
    }),
    on(dashboardActions.deleteDashboard, (state) => {
        return {
            ...state,
            errors: [],
            dashboard: baseDashboard,
        };
    }),
    on(dashboardActions.getDashboardUpdate, (state, { dashboardUpdates }) => {
        return {
            ...state,
            errors: [],
            dashboardUpdates,
        };
    }),
    on(dashboardActions.updateDashboardUpdate, (state, { dashboardUpdates }) => {
        const updatedDashboardUpdates = state.dashboardUpdates.map((update) => {
            const updatedUpdate = dashboardUpdates.find((u) => u.dashboardUpdateId === update.dashboardUpdateId);
            if (updatedUpdate) {
                return updatedUpdate;
            }
            return update;
        });
        return {
            ...state,
            dashboardUpdates: updatedDashboardUpdates,
            errors: [],
        };
    }),
    on(dashboardActions.createDashboardUpdate, (state, { dashboardUpdates }) => {
        return {
            ...state,
            dashboardUpdates,
            errors: [],
        };
    }),
    on(dashboardActions.deleteDashboardUpdate, (state, { dashboardUpdateId }) => {
        const updatedDashboardUpdates = state.dashboardUpdates.filter(
            (update) => update.dashboardUpdateId !== dashboardUpdateId
        );
        return {
            ...state,
            errors: [],
            dashboardUpdates: updatedDashboardUpdates,
        };
    })
);
