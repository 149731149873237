import React, { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { DetailHeader, DetailViewHeaderProps } from '@web/components/detail-view';
import { StyleSheet } from 'react-native';
import { Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

interface ContentSettingsLayoutProps {
    children?: ReactNode;
    headerProps?: DetailViewHeaderProps;
    message?: string;
}

interface BodyContainerProps {
    children?: ReactNode;
    divider?: boolean;
}

const useStyles = createUseStyles({
    divider: {
        backgroundColor: colors.grayThree,
        border: 0,
        height: 1,
        margin: '24px 0',
        width: '100%',
    },
});

const styles = StyleSheet.create({
    message: {
        color: colors.textDarkSecondary,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
});

export const BodyContainer: FC<BodyContainerProps> = ({ children, divider = false }) => {
    const classes = useStyles();

    return (
        <>
            {children && (
                <div className="mb-5 row">
                    <div className="col-md-8 offset-md-2">{children}</div>
                </div>
            )}
            {divider && (
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className={classes.divider} />
                    </div>
                </div>
            )}
        </>
    );
};

export const ContentSettingsLayout: FC<ContentSettingsLayoutProps> = ({ headerProps, children, message }) => {
    return (
        <>
            {headerProps && <DetailHeader {...headerProps} />}
            <div className="p-7 d-flex">
                <div className="d-flex w-100 flex-column">
                    <div className="container">
                        {message && (
                            <div className={`mb-5 row`}>
                                <div className="col-md-8 offset-md-2">
                                    <Typography variant="h4" style={styles.message}>
                                        {message}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};
