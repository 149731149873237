import React, { FC } from 'react';
import { AdminHub } from './views/admin-hub';
import { ContentSettingsMainWrapper } from '@web/content-settings/content-settings-main-wrapper';
import { ContentSettingsTechshare } from '@web/content-settings/content-settings-techshare';
import { ContentSettingsTechline } from '@web/content-settings/content-settings-techline';
import { EntityDetail } from './views/entity-detail';
import { EntityForm } from './views/entity-form';
import { FailCodeTeams } from './views/fail-code-teams';
import { FeedbackDetail } from './views/feedback-detail';
import { FeedbackFormBuilder } from './views/feedback-form-builder';
import { ManageEntities } from './views/manage-entities';
import { ManageFeedback } from './views/manage-feedback';
import { ManageRetailers } from './views/manage-retailers';
import { ManageUsers } from './views/manage-users';
import { ManageWorksheets } from './views/worksheets';
import { PermissionsProvider } from '@packages/contexts/permissions';
import { permissionsService, synonymsService } from '@web/services/singletons';
import { RetailerDetail } from './views/retailer-detail';
import { RetailerForm } from './views/retailer-form';
import { Routes, Route } from 'react-router-dom';
import { Synonyms } from './views/synonyms';
import { SynonymsProvider } from '@packages/contexts/synonyms';
import { TouchbookAttachments } from './views/toughbook-attachments';
import { WorksheetDetail } from './views/worksheets/worksheet-detail/worksheet-detail';
import { WorksheetsForm } from './views/worksheets/worksheet-form/worksheets-form';
import NoMatch from '@web/views/no-match';
import WorksheetPreview from './views/worksheets/worksheet-preview/worksheet-preview';
import { ContentSettingsQmrsDashboardMain } from '@web/content-settings/content-settings-main-qmrs-dashboard';

export const AdministrationRouter: FC = () => {
    return (
        <PermissionsProvider permissionsService={permissionsService}>
            <SynonymsProvider synonymsService={synonymsService}>
                <Routes>
                    <Route index element={<AdminHub />} />
                    <Route path="content-settings" element={<ContentSettingsMainWrapper />} />
                    <Route path="content-settings/qmrsdashboard" element={<ContentSettingsQmrsDashboardMain />} />
                    <Route path="content-settings/techline" element={<ContentSettingsTechline />} />
                    <Route path="content-settings/techshare" element={<ContentSettingsTechshare />} />
                    <Route path="entities" element={<ManageEntities />} />
                    <Route path="entities/:entityId" element={<EntityDetail />} />
                    <Route path="entities/:entityId/edit" element={<EntityForm />} />
                    <Route path="entities/new" element={<EntityForm />} />
                    <Route path="fail-code-teams" element={<FailCodeTeams />} />
                    <Route path="feedback" element={<ManageFeedback />} />
                    <Route path="feedback/:feedbackFormId" element={<FeedbackDetail />} />
                    <Route path="feedback/:feedbackFormId/edit" element={<FeedbackFormBuilder />} />
                    <Route path="feedback/new" element={<FeedbackFormBuilder />} />
                    <Route path="retailers" element={<ManageRetailers />} />
                    <Route path="retailers/:retailerId" element={<RetailerDetail />} />
                    <Route path="retailers/:retailerId/edit" element={<RetailerForm />} />
                    <Route path="synonyms" element={<Synonyms />} />
                    <Route path="toughbook-attachments" element={<TouchbookAttachments />} />
                    <Route path="users" element={<ManageUsers />} />
                    <Route path="worksheet/:worksheetFormId" element={<WorksheetDetail />} />
                    <Route path="worksheet/:worksheetFormId/edit" element={<WorksheetsForm />} />
                    <Route path="worksheet/new" element={<WorksheetsForm />} />
                    <Route path="worksheet/preview/:worksheetId" element={<WorksheetPreview />} />
                    <Route path="worksheets" element={<ManageWorksheets />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </SynonymsProvider>
        </PermissionsProvider>
    );
};
