import React, { useMemo } from 'react';
import { Button } from '@packages/ui/shared';
import { ContentSettingsLayout } from './components/content-settings-layout';
import { DetailViewHeaderProps } from '@web/components/detail-view';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ContentSettingsTechline: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const headerProps: DetailViewHeaderProps = useMemo(
        () => ({
            breadcrumbs: [
                { to: '/administration', title: t('administration:breadcrumbs.adminHub', 'Administration Hub') },
                {
                    to: '/administration/content-settings',
                    title: t('administration:breadcrumbs.contentSettings', 'Content Settings'),
                },
                {
                    to: '/administration/content-settings/techline',
                    title: t('administration:breadcrumbs.edit', 'Edit'),
                },
            ],
            subtitle: t('administration:techline.subtitle', 'Techline Dashboard'),
            rightSideItems: [
                <Button key="save" variant="outlinePrimary" onPress={() => navigate(-1)}>
                    {t('administration:techline.exit', 'Exit')}
                </Button>,
            ],
            hasBack: true,
        }),
        [t, navigate]
    );

    return (
        <ContentSettingsLayout
            headerProps={headerProps}
            message={t(
                'administration:techline.message',
                'This message will appear to all users that visit the techline page.'
            )}
        />
    );
};
