import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useQmrDispatch, useQmrState, patchQmr } from '@packages/contexts/qmrs';
import { useAbortController } from '@packages/core/http';
import { TextareaHelper, Button, Icon, Label } from '@packages/ui/shared';
import { CauseInfoPopup } from '@packages/ui/qmr/popups';
import { httpClient, qmrsService } from '@web/services/singletons';
import { PatchQmrDto, PartNumber } from '@packages/models/api';

import { AsyncTypeahead, TypeaheadState, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';

const usePartNumberDraftStyles = createUseStyles({
    suggestedPartText: {
        marginBottom: 0,
        ...fonts.xs,
        color: colors.black,
    },
    partNumberText: {
        marginBottom: 0,
        ...fonts.xs,
    },
});

const CauseDraft: FC = () => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');
    const qmrDispatch = useQmrDispatch();

    const [didInit, setDidInit] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [cause, setCause] = useState(qmr?.cause ?? '');
    const [causeError, setCauseError] = useState('');
    const [partNumber, setPartNumber] = useState(qmr?.partNumber ?? '');
    const [searchResults, setSearchResults] = useState<PartNumber[]>([]);
    const [typeaheadOpen, setTypeaheadOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const classes = usePartNumberDraftStyles();

    const queryPartNumbers = (query: string) => {
        if (!query || !qmr) {
            return;
        }

        setIsLoading(true);
        setTypeaheadOpen(true);

        qmrsService
            .fetchPartNumbers({
                qmrId: qmr.qmrId,
                query,
                ignoreCache: true,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    setIsLoading(false);
                    throw response.data;
                }

                setSearchResults(response.data.parts);
                setIsLoading(false);
            });
    };

    const saveChanges = useCallback(
        (qmrPatch: PatchQmrDto) => {
            if (!qmr) {
                return;
            }

            patchQmr({
                qmrId: qmr.qmrId,
                qmrsService,
                qmrDispatch,
                signal: abortSignalRef.current,
                qmrPatch,
                isLocalPatch: !!editRouteMatch,
            });
        },
        [abortSignalRef, editRouteMatch, qmr, qmrDispatch]
    );

    const handleBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!qmr) {
            return;
        }

        setTypeaheadOpen(false);

        if (e.target.value && qmr.partNumber !== e.target.value) {
            setPartNumber(e.target.value);
            const qmrPatch = { partNumber: e.target.value };
            patchQmr({
                qmrId: qmr.qmrId,
                qmrsService,
                qmrDispatch,
                isLocalPatch: true,
                qmrPatch,
            });
            saveChanges(qmrPatch);
        }

        setSearchResults([]);
    };

    const timeoutRefs = useRef<{ [k: string]: NodeJS.Timeout }>({});
    useEffect(() => {
        if (timeoutRefs.current.cause) {
            clearTimeout(timeoutRefs.current.cause);
        }

        if ((!qmr?.cause && !cause) || qmr?.cause === cause) {
            return;
        }

        timeoutRefs.current.cause = global.setTimeout(() => {
            saveChanges({ cause });
        }, 60000);
    }, [saveChanges, cause, qmr?.cause]);

    useEffect(() => {
        if (timeoutRefs.current.partNumber) {
            clearTimeout(timeoutRefs.current.partNumber);
        }

        if ((!qmr?.partNumber && !partNumber) || qmr?.partNumber === partNumber) {
            return;
        }

        timeoutRefs.current.partNumber = global.setTimeout(() => {
            saveChanges({ partNumber });
        }, 60000);
    }, [saveChanges, partNumber, qmr?.partNumber]);

    useEffect(() => {
        if (!qmr || didInit) {
            return;
        }

        setDidInit(true);

        setCause(qmr.cause);
        setPartNumber(qmr.partNumber || '');
    }, [didInit, qmr]);

    return (
        <>
            <CauseInfoPopup
                show={showInfoPopup}
                onOkPress={() => setShowInfoPopup(false)}
                onModalHide={() => setShowInfoPopup(false)}
            />

            <h4 className="mb-4">{t('qmr:sections.cause.title')}</h4>

            <div className="mb-6">
                <div className="mb-3">
                    <Label optional>{t('qmr:inputs.partNumber.label')}</Label>
                </div>

                <AsyncTypeahead
                    emptyLabel="none"
                    isLoading={isLoading}
                    labelKey={(option) => `${option.partNumber}`}
                    open={typeaheadOpen}
                    renderMenu={(results, menuProps, ...args) => {
                        const typeaheadState = (args as any)[0];

                        if (!typeaheadState.text) {
                            return null;
                        }

                        const title = isLoading
                            ? t('text:loading.searching', 'Searching')
                            : typeaheadState.text && results.length === 0
                              ? t('text:noPartMatches', 'Part number not found - Please verify and try again.')
                              : t('text:suggested');

                        return (
                            <Menu {...menuProps}>
                                <Menu.Header>
                                    <p className={classes.suggestedPartText}>{title}</p>
                                </Menu.Header>

                                {results.map((result, index) => {
                                    if ((result as any).paginationOption) {
                                        return null;
                                    }

                                    return (
                                        <MenuItem key={index} option={result} position={index}>
                                            <p className={classes.partNumberText}>
                                                <Highlighter search={typeaheadState.text}>
                                                    {`${result.partNumber} - ${result.partDescription}`}
                                                </Highlighter>
                                            </p>
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        );
                    }}
                    renderMenuItemChildren={(item) => {
                        return (
                            <>
                                <p className="mb-0">
                                    {item.partNumber}
                                    {item.partDescription && <span>{`- ${item.partDescription}`}</span>}
                                </p>
                            </>
                        );
                    }}
                    flip={true}
                    id="qmr-partNumber"
                    placeholder={t('qmr:inputs.partNumber.placeholder', 'Enter Part Number')}
                    useCache={false}
                    minLength={1}
                    onSearch={queryPartNumbers}
                    onChange={async ([code]) => {
                        if (!qmr) {
                            return;
                        }

                        setTypeaheadOpen(true);

                        if (code && qmr.partNumber !== code.partNumber) {
                            setPartNumber(code.partNumber);
                            const qmrPatch = { partNumber };
                            patchQmr({
                                qmrId: qmr.qmrId,
                                qmrsService,
                                qmrDispatch,
                                isLocalPatch: true,
                                qmrPatch,
                            });
                            saveChanges(qmrPatch);
                        }

                        setTypeaheadOpen(false);
                        setSearchResults([]);
                    }}
                    options={searchResults}
                    onBlur={(e: Event) => {
                        if (e.target instanceof HTMLInputElement) {
                            handleBlur(e as unknown as React.ChangeEvent<HTMLInputElement>);
                        }
                    }}
                    defaultSelected={partNumber ? [{ partNumber: partNumber, partDescription: '' }] : []}
                />
            </div>

            <TextareaHelper
                editable={!!editRouteMatch ? qmr?.capabilities.editCccOnQmr : true}
                controlId="qmr-cause"
                value={cause}
                label={t('qmr:inputs.cause.label')}
                labelRightElement={
                    <Button
                        variant="ghost-blue"
                        title={t('qmr:inputs.cause.hint')}
                        iconLeft={<Icon name="info" color="blueOne" />}
                        onPress={() => setShowInfoPopup(true)}
                    />
                }
                required
                placeholder={t('qmr:inputs.cause.placeholder')}
                errorMessage={causeError}
                onChangeText={(value) => {
                    httpClient.refreshToken();
                    setCause(value);
                    setCauseError(value.trim().length ? '' : t('qmr:inputs.cause.errorMessage'));
                    // Make sure when it is empty, we mark it definitively as empty and strictly do not allow empty submissions.
                    if (!value.trim().length) {
                        if (!qmr) {
                            return;
                        }

                        const qmrPatch = { cause: value };
                        patchQmr({
                            qmrId: qmr.qmrId,
                            qmrsService,
                            qmrDispatch,
                            isLocalPatch: true,
                            qmrPatch,
                        });
                        saveChanges(qmrPatch);
                    }
                }}
                onBlur={() => {
                    if (!qmr) {
                        return;
                    }

                    if (qmr.cause !== cause) {
                        const qmrPatch = { cause };
                        patchQmr({
                            qmrId: qmr.qmrId,
                            qmrsService,
                            qmrDispatch,
                            isLocalPatch: true,
                            qmrPatch,
                        });
                        saveChanges(qmrPatch);
                    }
                }}
            />
        </>
    );
};

export default CauseDraft;
