import React, { useEffect, useMemo, useState } from 'react';
import { BodyContainer, ContentSettingsLayout } from './components/content-settings-layout';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { dashboardService } from '@web/services/singletons';
import { isEmpty } from 'lodash';
import { useAbortController } from '@packages/core/http';
import { useDashboardState, useDashboardDispatch } from '@packages/contexts/dashboard/dashboard.hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    addOrUpdateDashboardUpdate,
    createOrUpdateDashboard,
    deleteDashboardUpdate,
    fetchDashboard,
    fetchDashboardUpdates,
} from '@packages/contexts/dashboard/dashboard.context';
import { EditModalValue, GenericContentCardsContent, GenericContentManager } from './components/common/generic-content';
import { ToastNotification } from '@web/components/toast-notification';

const contentAddMessage = 'Your content changes were published';
const contentDeletedMessage = 'Content deleted';

export const ContentSettingsTechshare: React.FC = () => {
    const { t } = useTranslation();
    const dashboardState = useDashboardState();
    const dashboardDispatch = useDashboardDispatch();
    const { abortSignalRef } = useAbortController();
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState<{ show: boolean; message?: string }>({ show: false, message: '' });

    const { dashboard, dashboardUpdates } = dashboardState;

    const dashboardContent: GenericContentCardsContent[] = !isEmpty(dashboard?.dashboardId)
        ? [
              {
                  description: dashboard.bannerMessage,
                  displayOrder: dashboard.displayOrder,
                  displayReadMore: 'true',
                  headingText: dashboard.bannerHeader,
                  id: dashboard.dashboardId,
                  link: dashboard.bannerLink,
              },
          ]
        : [];

    const dashboardUpdatesContent: GenericContentCardsContent[] = dashboardUpdates?.map((update) => ({
        description: update.description,
        displayOrder: update.displayOrder,
        displayReadMore: 'true',
        headingText: update.heading,
        id: update.dashboardUpdateId,
        link: update.link,
        type: update.type,
    }));

    useEffect(() => {
        fetchDashboard({
            dashboardDispatch,
            dashboardService,
            signal: abortSignalRef.current,
        });
        fetchDashboardUpdates({
            dashboardDispatch,
            dashboardService,
            signal: abortSignalRef.current,
        });
    }, [abortSignalRef, dashboardDispatch]);

    const saveBanner = async (banner: EditModalValue) => {
        await createOrUpdateDashboard({
            dashboardDispatch,
            dashboardService,
            dashboardId: dashboard.dashboardId,
            newDashboard: {
                dashboardId: banner.id,
                bannerMessage: banner.description,
                bannerLink: banner.link,
                bannerHeader: banner.title,
            },
        });
        setShowToast({ show: true, message: contentAddMessage });
        return banner;
    };

    const deleteBanner = async (id: string) => {
        await createOrUpdateDashboard({
            dashboardDispatch,
            dashboardService,
            dashboardId: dashboard.dashboardId,
            newDashboard: {
                ...dashboard,
                dashboardId: id,
                deleted: true,
            },
        });
        setShowToast({ show: true, message: contentDeletedMessage });
        return id;
    };

    const saveDashboardUpdates = async (update: EditModalValue) => {
        addOrUpdateDashboardUpdate({
            dashboardDispatch,
            dashboardService,
            dashboardUpdate: {
                dashboardUpdateId: update.id,
                description: update.description,
                displayOrder: update.displayOrder,
                heading: update.title || update.headingText,
                link: update.link,
                type: update.type,
            },
        });
        setShowToast({ show: true, message: contentAddMessage });
        return update;
    };

    const deleteDashboardUpdates = async (id: string) => {
        await deleteDashboardUpdate({
            dashboardDispatch,
            updateDashboardId: id,
            dashboardService,
        });
        setShowToast({ show: true, message: contentDeletedMessage });
        return id;
    };

    const headerProps = useMemo(
        () => ({
            breadcrumbs: [
                { to: '/administration', title: t('administration:breadcrumbs.adminHub', 'Administration Hub') },
                { to: '/administration/content-settings', title: 'Content Settings' },
                { to: '/administration/content-settings/techshare', title: 'Edit' },
            ],
            subtitle: 'Techshare Dashboard',
            rightSideItems: [
                <Button key="exit" variant="outlinePrimary" onPress={() => navigate(-1)}>
                    Exit
                </Button>,
            ],
            hasBack: true,
        }),
        [t, navigate]
    );

    return (
        <>
            <ToastNotification
                show={showToast.show}
                onClose={() => setShowToast({ ...showToast, show: false })}
                body={
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <Icon name="checkmark" size={20} color="white" />
                        <Typography variant="h6" color="white">
                            {showToast.message}
                        </Typography>
                    </div>
                }
                autoHideTime={2000}
                variant="success"
                position="top-center"
            />
            <ContentSettingsLayout
                headerProps={headerProps}
                message="This message will appear to all users that visit the techshare page."
            >
                <BodyContainer divider={true}>
                    <GenericContentManager
                        genericContentProps={{
                            headerProps: {
                                title: 'Banners',
                                buttonLabel: 'Add Banner',
                            },
                            cardProps: {
                                title: 'Banner',
                                hideCopyButton: true,
                            },
                            modalProps: {
                                title: 'Banner',
                                submitButtonText: dashboardContent.length > 0 ? 'Edit Banner' : 'Add Banner',
                                formFields: [
                                    {
                                        type: 'TextInput',
                                        fieldName: 'title',
                                        required: true,
                                        contentFieldMapper: 'headingText',
                                        placeholder: 'Write title here',
                                        label: 'Title',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'description',
                                        contentFieldMapper: 'description',
                                        required: true,
                                        placeholder: 'Write copy here',
                                        label: 'Description',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'link',
                                        contentFieldMapper: 'link',
                                        placeholder: 'Write Edit here',
                                        label: 'Link',
                                        required: false,
                                    },
                                ],
                            },
                            contents: dashboardContent,
                            saveCallback: saveBanner,
                            deleteCallBack: deleteBanner,
                            maxInstance: 1,
                            sortingEnabled: false,
                            confirmDeleteModal: true,
                        }}
                    />
                </BodyContainer>

                <BodyContainer>
                    <GenericContentManager
                        genericContentProps={{
                            headerProps: { title: 'Updates', buttonLabel: 'Add Update' },
                            cardProps: { title: 'Update', hideCopyButton: false },
                            modalProps: {
                                title: 'Update',
                                submitButtonText: 'Add Update',
                                formFields: [
                                    {
                                        type: 'TextInput',
                                        fieldName: 'type',
                                        required: true,
                                        contentFieldMapper: 'type',
                                        placeholder: 'Write type here',
                                        label: 'Type',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'title',
                                        required: true,
                                        contentFieldMapper: 'headingText',
                                        placeholder: 'Write title here',
                                        label: 'Title',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'description',
                                        contentFieldMapper: 'description',
                                        required: true,
                                        placeholder: 'Write copy here',
                                        label: 'Description',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'link',
                                        contentFieldMapper: 'link',
                                        required: false,
                                        placeholder: 'Write Edit here',
                                        label: 'Link',
                                    },
                                ],
                            },
                            contents: dashboardUpdatesContent,
                            saveCallback: saveDashboardUpdates,
                            deleteCallBack: deleteDashboardUpdates,
                            maxInstance: 3,
                            sortingEnabled: false,
                            confirmDeleteModal: true,
                        }}
                    />
                </BodyContainer>
            </ContentSettingsLayout>
        </>
    );
};
