import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useAbortController } from '@packages/core/http';
import { analyticsService, qmrsService } from '@web/services/singletons';

import { Qmr, QmrStatusId } from '@packages/models/api';
import useQuery from '@web/core/hooks/use-query';

import { Button, Icon, Typography, AlertBar } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { QmrTableHead, QmrTableRow } from '@web/qmr/components/qmr-table';
import { TableRenderer } from '@web/components/table/table-renderer';
import { SORT_DIRECTION, TablePagination } from '@web/components/table';
import { useAuthState } from '@packages/contexts/auth';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { useSummariesState } from '@web/core/hooks/use-summaries';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { useAlert } from '@packages/ui/shared';
import { useQmrTableActions } from '@packages/contexts/qmrs';
import QmrSearch from '@web/qmr/components/qmr-search';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { LayoutAnimation } from 'react-native';

const useStyles = createUseStyles({
    flexGap: {
        gap: 20,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    navTabStyle: {
        marginRight: 10,
    },
    activeNavTabStyle: {
        borderBottom: `2px solid ${colors.blueOne}`,
        color: colors.blueOne,
        paddingBottom: 5,
    },
});
export interface QmrTableRowModel extends Qmr {
    rowId: string;
    checked: boolean;
}

interface Props {
    showNQQmrs?: boolean;
}

const Qmrs: FC<Props> = ({ showNQQmrs = false }) => {
    const selectedTabHeadStyles = {
        borderBottomColor: colors.blueOne,
        color: colors.blueOne,
        borderBottomWidth: 2,
        paddingBottom: 5,
    };
    const classes = useStyles();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const { t } = useTranslation();
    const { account } = useAuthState();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const { qmrSummaries } = useSummariesState();
    const alert = useAlert();
    const { abortSignalRef } = useAbortController();

    const { abortSignalRef: searchAbortSignal, signalAbort } = useAbortController();
    const [isLoading, setIsLoading] = useState(false);
    const [qmrTableRows, setQmrTableRows] = useState<QmrTableRowModel[]>([]);

    const queryParams = useQuery();
    const qmrStatusId = (queryParams.get('qmrStatusId') as QmrStatusId) || undefined;
    const pageFromQuery = queryParams.get('page');
    const sizeFromQuery = queryParams.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const parsedSortOrder = queryParams.get('sortOrder');
    const parsedSortDirection = queryParams.get('sortDir');
    const quickSearchQuery = queryParams.get('query') || '';
    const hasQuickFilters = useMemo(() => {
        return (
            queryParams.has('retailer') ||
            queryParams.has('submissionDate') ||
            queryParams.has('carline') ||
            queryParams.has('yearFrom') ||
            queryParams.has('yearTo') ||
            queryParams.has('isImported') ||
            queryParams.has('hasTurboIndicator') ||
            queryParams.has('hasAttachments')
        );
    }, [queryParams]);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedQmrs, setSelectedQmrs] = useState<QmrTableRowModel[]>([]);

    const [totalEntries, setTotalEntires] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');
    const vinQmrDashboardRouteMatch = useMatch('/vin/:id/qmrs');
    const [vinQmrs, setVinQmrs] = useState<QmrTableRowModel[]>([]);
    const { selectedVin } = useVinSessionState();

    const qmrTableActions = useQmrTableActions({
        qmrs: selectedQmrs.length ? selectedQmrs : null,
        alert,
        qmrsService,
    });

    const pageTitle = useMemo(() => {
        const qmrHeaderMap: Record<QmrStatusId, string> = {
            [QmrStatusId.Draft]: 'My Drafts',
            [QmrStatusId.PendingReview]: 'Pending Review',
            [QmrStatusId.PendingReviewUpdated]: 'Updated - Pending Review',
            [QmrStatusId.UnderReview]: 'Under Review',
            [QmrStatusId.FurtherStudy]: 'Further Study',
            [QmrStatusId.NoActionNeeded]: 'No Action Needed',
            [QmrStatusId.Escalated]: 'Escalated',
            [QmrStatusId.EscalatedAndPartCollection]: 'Escalated - Part Collection',
            [QmrStatusId.MoreInfoRequested]: 'More Info Requested',
        };

        const nqQMRTitleMap: Record<QmrStatusId | 'All', string> = {
            All: 'Non-Quality Reports',
            [QmrStatusId.Draft]: 'My Drafts',
            [QmrStatusId.PendingReview]: 'Pending Review',
            [QmrStatusId.PendingReviewUpdated]: 'Updated - Pending Review',
            [QmrStatusId.UnderReview]: 'Under Review',
            [QmrStatusId.FurtherStudy]: 'Further Study',
            [QmrStatusId.NoActionNeeded]: 'No Action Needed',
            [QmrStatusId.Escalated]: 'Escalated',
            [QmrStatusId.EscalatedAndPartCollection]: 'Escalated - Part Collection',
            [QmrStatusId.MoreInfoRequested]: 'More Info Requested',
        };

        const item = qmrSummaries.find((s) => s.qmrStatusId === qmrStatusId);
        if (item) {
            if (showNQQmrs) {
                if (item.qmrStatusId && nqQMRTitleMap[item.qmrStatusId]) {
                    return nqQMRTitleMap[item.qmrStatusId];
                }
                return nqQMRTitleMap.All;
            }
            return item.description;
        } else {
            return qmrHeaderMap[qmrStatusId as QmrStatusId] || ' ';
        }
    }, [qmrStatusId, qmrSummaries, showNQQmrs]);

    const toggleQuickFilterPanel = useCallback(() => {
        toggleOverlay('qmrQuickFilters');
    }, [toggleOverlay]);

    const handleClearQuickFilters = useCallback(() => {
        const newQueryParams = new URLSearchParams();

        if (qmrStatusId) {
            newQueryParams.set('qmrStatusId', qmrStatusId);
        }

        if (sizeFromQuery) {
            newQueryParams.set('size', sizeFromQuery);
        }

        if (quickSearchQuery) {
            newQueryParams.set('query', quickSearchQuery);
        }

        navigate(`${pathname}?${newQueryParams.toString()}`);
    }, [navigate, pathname, qmrStatusId, quickSearchQuery, sizeFromQuery]);

    const location = useLocation();
    const [isDsqmStatusPresent, setIsDsqmStatusPresent] = useState<boolean>(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const hasDsqmStatusId = searchParams.has('dsqmStatusId');
        setIsDsqmStatusPresent(hasDsqmStatusId);
    }, [location.search]);

    useEffect(() => {
        if (!vinQmrDashboardRouteMatch) {
            signalAbort();
            setIsLoading(true);
            const params = new URLSearchParams(search);
            params.set('page', '' + parsedPage);
            params.set('size', '' + parsedSize);
            if (qmrStatusId) {
                params.delete('qmrStatusId');
                params.set(showNQQmrs ? 'dsqmStatusId' : 'qmrStatusId', qmrStatusId);
            }
            if (showNQQmrs) {
                params.set('fetchNqrs', 'true');
            }
            qmrsService
                .fetchQmrsList({
                    queryString: params.toString(),
                    ignoreCache: true,
                    signal: searchAbortSignal.current,
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }

                    const qmrsWithCheckedProperty = response.data.qmrs.map((qmr) => {
                        const qmrWithCheckedProperty = Object.assign(qmr, { rowId: qmr.qmrId, checked: false });
                        return qmrWithCheckedProperty;
                    });

                    setIsLoading(false);
                    setPageStartCount(response.data.displayStartCountDescription);
                    setPageEndCount(response.data.displayEndCountDescription);
                    setPageTotalCount(response.data.totalCountDescription);
                    setTotalEntires(response.data.totalCount);
                    setQmrTableRows(qmrsWithCheckedProperty);
                })
                .catch((error) => {
                    window.alert(error.message);
                    setIsLoading(false);
                });
        }
    }, [parsedPage, parsedSize, qmrStatusId, search, searchAbortSignal, showNQQmrs, signalAbort]);

    useEffect(() => {
        if (selectedVin) {
            signalAbort();
            setIsLoading(true);
            const params = new URLSearchParams(search);
            params.set('vin', '' + selectedVin?.vinRecord.vin);
            params.set('page', '' + parsedPage);
            params.set('size', '' + parsedSize);
            if (qmrStatusId) {
                params.delete('qmrStatusId');
                params.set(showNQQmrs ? 'dsqmStatusId' : 'qmrStatusId', qmrStatusId);
            }

            qmrsService
                .fetchQmrsList({
                    queryString: params ? params.toString() : '',
                    ignoreCache: true,
                    signal: abortSignalRef.current,
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }
                    const qmrsWithCheckedProperty = response.data.qmrs.map((qmr) => {
                        const qmrWithCheckedProperty = Object.assign(qmr, { rowId: qmr.qmrId, checked: false });
                        return qmrWithCheckedProperty;
                    });
                    setIsLoading(false);
                    setVinQmrs(qmrsWithCheckedProperty);
                    setPageStartCount(response.data.displayStartCountDescription);
                    setPageEndCount(response.data.displayEndCountDescription);
                    setPageTotalCount(response.data.totalCountDescription);
                    setTotalEntires(response.data.totalCount);
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                })
                .catch((error) => {
                    window.alert(error.message);
                    setIsLoading(false);
                });
        }
    }, [selectedVin, parsedPage, parsedSize, search, searchAbortSignal, signalAbort, qmrStatusId]);

    const handleSelectionChange = useCallback((selectedQmrs: QmrTableRowModel[]) => {
        setSelectedQmrs(selectedQmrs);
    }, []);

    const handleSortChange = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));

            navigate(`${pathname}?${params.toString()}`);
            setSelectAll(false);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));

            navigate(`${pathname}?${params.toString()}`);
            setSelectAll(false);
        },
        [navigate, pathname, search]
    );

    return (
        <>
            <AlertBar
                show={alert.isOpen}
                success={alert.type === 'success'}
                error={alert.type === 'error'}
                onClose={alert.close}
            >
                <Typography>{alert.content}</Typography>
            </AlertBar>

            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <Typography variant="h2">{pageTitle}</Typography>

                    <div className={`d-flex flex-row ${classes.flexGap} mt-5`}>
                        <div onClick={() => navigate(pathname)} className={`${classes.cursorPointer} d-flex`}>
                            <Typography
                                style={qmrStatusId === undefined ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                All
                            </Typography>
                        </div>
                        {!showNQQmrs && (
                            <div
                                onClick={() => navigate(`${pathname}?qmrStatusId=DRAFT`)}
                                className={`${classes.cursorPointer} d-flex`}
                            >
                                <Typography
                                    style={qmrStatusId === 'DRAFT' ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    My Draft
                                </Typography>
                            </div>
                        )}
                        <div
                            onClick={() => {
                                navigate(`${pathname}?qmrStatusId=PENDING_REVIEW`);
                                analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_PENDING_QMR, {
                                    value: 'Pending Review Tab',
                                });
                            }}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={qmrStatusId === 'PENDING_REVIEW' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Pending Review
                            </Typography>
                        </div>
                        {!showNQQmrs && (
                            <div
                                onClick={() => navigate(`${pathname}?qmrStatusId=PENDING_REVIEW_UPDATED`)}
                                className={`${classes.cursorPointer} d-flex`}
                            >
                                <Typography
                                    style={qmrStatusId === 'PENDING_REVIEW_UPDATED' ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    Updated Pending Review
                                </Typography>
                            </div>
                        )}
                        <div
                            onClick={() => navigate(`${pathname}?qmrStatusId=UNDER_REVIEW`)}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={qmrStatusId === 'UNDER_REVIEW' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Under Review
                            </Typography>
                        </div>
                        <div
                            onClick={() => navigate(`${pathname}?qmrStatusId=FURTHER_STUDY`)}
                            className={`${classes.navTabStyle} ${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={qmrStatusId === 'FURTHER_STUDY' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Further Study
                            </Typography>
                        </div>
                        <div
                            onClick={() => navigate(`${pathname}?qmrStatusId=NO_ACTION_NEEDED`)}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={qmrStatusId === 'NO_ACTION_NEEDED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                No Action Needed
                            </Typography>
                        </div>
                    </div>

                    <QmrSearch
                        bottomStyleFlag={!vinQmrDashboardRouteMatch}
                        showNQQmrs={showNQQmrs}
                        path={pathname}
                        account={account}
                        includeFilters={false}
                    />

                    {!vinQmrDashboardRouteMatch && (
                        <div className="my-4 d-flex">
                            <Button
                                active={hasQuickFilters}
                                variant="ghost-blue"
                                iconLeft={<Icon color="blueOne" name="filter-list" />}
                                onPress={toggleQuickFilterPanel}
                            >
                                {hasQuickFilters
                                    ? t('views:qmrs.actions.filterToggle.active', 'Filters applied')
                                    : t('views:qmrs.actions.filterToggle.inactive', 'Filters')}
                            </Button>
                            {hasQuickFilters && (
                                <div className="ml-1">
                                    <Button variant="ghost-blue" onPress={handleClearQuickFilters}>
                                        <Icon color="blueOne" name="x-close" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    <TableRenderer
                        isLoading={isLoading}
                        selectAll={selectAll}
                        selectAllSetter={setSelectAll}
                        tableRowsData={vinQmrDashboardRouteMatch ? vinQmrs : qmrTableRows}
                        tableRowsDataSetter={setQmrTableRows}
                        onSelectionChange={handleSelectionChange}
                        noResultsTitle={'No reports found'}
                        tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                            return (
                                <QmrTableHead
                                    selectedCount={selectedQmrs.length}
                                    selectedItems={selectedQmrs}
                                    selectAll={selectAllValue}
                                    sortOrder={parsedSortOrder}
                                    sortDirection={parsedSortDirection ? (parsedSortDirection as SORT_DIRECTION) : null}
                                    onSelectAllChange={selectAllChangeHandler}
                                    onSort={handleSortChange}
                                    onActionPress={qmrTableActions.handleActionPress}
                                    isDsqmStatusPresent={isDsqmStatusPresent}
                                    showNQQmrs={showNQQmrs}
                                />
                            );
                        }}
                        tableBodyRowRenderer={(rowData, rowSelectHandler) => {
                            return (
                                <QmrTableRow
                                    key={rowData.qmrId}
                                    qmr={rowData}
                                    checked={rowData.checked as boolean}
                                    onCheckboxChange={rowSelectHandler}
                                    listQueryParams={queryParams.toString()}
                                    isDsqmStatusPresent={isDsqmStatusPresent}
                                    showNQQmrs={showNQQmrs}
                                />
                            );
                        }}
                    />
                    <TablePagination
                        page={parsedPage}
                        size={parsedSize}
                        total={totalEntries}
                        disabled={isLoading}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                        displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
                    />
                </div>
            </div>
        </>
    );
};

export default Qmrs;
