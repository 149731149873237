/* eslint-disable react-native/no-inline-styles */
import React, { FC, ReactNode } from 'react';
import { ActivityIndicator, Platform, StyleSheet, TouchableOpacity, View, StyleProp, ViewStyle } from 'react-native';

import colors from '@packages/core/styles/colors';
import { AssetType, AssetProcessingState } from '@packages/models/api';

import { AssetStatusPill, AssetProgressBar, Typography, TextBadge } from '../shared';

export interface QmrAssetListItemProps {
    assetId?: string;
    assetTypeId: AssetType;
    createdTimestampDescription?: string;
    description?: string;
    processingStateId?: AssetProcessingState;
    title: ReactNode; // switched from type String to ReactNode. This is to allow for passing JSX elements, as well as primitive types.
    progress?: number;
    isRestricted?: boolean;
    thumbnailRenderer: (size: { height: number; width: number }) => JSX.Element | null;
    moreOptionsRenderer: () => JSX.Element | null;
    onCancelUpload?: () => void;
    style?: StyleProp<ViewStyle>;
    isAdditionalUpload?: boolean;
}

export const QmrAssetListItem: FC<QmrAssetListItemProps> = (item) => {
    const itemSize = {
        height: 80,
        width: 80,
    };

    return (
        <View style={[styles.listItem, item.style]}>
            <View style={styles.listItemMediaOuter}>
                <TouchableOpacity
                    style={styles.container}
                    disabled={false}
                    onPress={() => {
                        // This is disabled and does nothing.
                        // perhaps need to launch a "viewer" of some sort
                        // depending on the assetTypeId
                    }}
                >
                    {item.thumbnailRenderer(itemSize) || (
                        <View style={[styles.processingStateContainer, itemSize]}>
                            <ActivityIndicator size="large" color={colors.blueOne} />
                        </View>
                    )}
                </TouchableOpacity>
            </View>

            <View style={styles.listItemInformationOuter}>
                <View style={styles.verticalInfo}>
                    <View style={styles.listItemInformationRow}>
                        <View style={styles.listItemInformation}>
                            <View style={styles.listItemTitleRow}>
                                <Typography variant="h6" style={styles.listItemTitle}>
                                    {item.title}
                                </Typography>
                            </View>

                            <Typography variant="caption" style={styles.listItemDescription}>
                                {item.description}
                            </Typography>
                        </View>

                        {typeof item.moreOptionsRenderer === 'function' ? (
                            <View style={styles.listItemControlOuter}>{item.moreOptionsRenderer()}</View>
                        ) : null}
                    </View>
                    {item.isAdditionalUpload && (
                        <View style={styles.additionalUploadOuter}>
                            <TextBadge>ADDED</TextBadge>
                            <Typography variant="caption" color="graySix" style={{ marginLeft: 12 }}>
                                {item.createdTimestampDescription}
                            </Typography>
                        </View>
                    )}
                </View>

                {typeof item.progress === 'number' ? (
                    <AssetProgressBar percent={item.progress} canCancel={true} onCancel={item.onCancelUpload} />
                ) : (
                    item.processingStateId &&
                    item.processingStateId !== AssetProcessingState.Uploading && (
                        <AssetStatusPill status={item.processingStateId} />
                    )
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    listItem: {
        flexDirection: 'row',
        paddingVertical: 16,
        paddingHorizontal: Platform.select({ web: 0, default: 20 }),
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    listItemMediaOuter: {
        width: 80,
        height: 80,
        flexShrink: 0,
        backgroundColor: colors.grayTwo,
    },
    verticalInfo: {
        flex: 1,
        justifyContent: 'space-between',
    },
    listItemInformationOuter: {
        flex: 1,
        paddingLeft: 24,
    },
    listItemInformationRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listItemInformation: {
        flex: 1,
    },
    listItemTitleRow: {
        flexDirection: 'row',
    },
    listItemTitle: {
        marginBottom: 8,
    },
    listItemDescription: {
        marginBottom: 12,
    },
    listItemControlOuter: {
        width: 44,
        flexShrink: 0,
        marginRight: -9,
    },

    container: {
        backgroundColor: colors.grayOne,
    },
    processingStateContainer: {
        justifyContent: 'center',
    },
    restrictionIcon: {
        marginLeft: 4,
    },
    additionalUploadOuter: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
