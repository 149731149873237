import React, { useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Empty, EmptyProps } from '../empty/empty';
import { LoadingCircle, StsIconName } from '@packages/ui/shared';
import { Table, TableHead, TableBody, TableRow, TableCell } from './';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    tableOuter: ({ isLoading }: { isLoading: boolean }) => ({
        height: '100%',
        maxHeight: 'calc(100vh - 350px)',
        minHeight: 'calc(100vh - 350px)',
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
    }),
    loadingCircleContainer: {
        minHeight: '40vh',
    },
    loaderCircleOuter: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    noResultsOuter: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    iconOuter: {
        alignItems: 'center',
        backgroundColor: colors.grayOne,
        borderRadius: '50%',
        display: 'flex',
        height: 120,
        justifyContent: 'center',
        marginBottom: 20,
        marginLeft: 32,
        marginTop: 12,
        width: 120,
    },
    noResultsContainer: {
        height: 48,
        padding: 10,
        width: 210,
    },
});

type BaseTableRowDataModel<T extends Record<string, any>> = T & {
    checked?: boolean;
    rowId: string;
};

interface TableRendererProps<T extends Record<string, any>> {
    isLoading?: boolean;
    tableRowsData: BaseTableRowDataModel<T>[];
    tableRowsDataSetter: React.Dispatch<React.SetStateAction<BaseTableRowDataModel<T>[]>>;
    selectAll?: boolean;
    selectAllSetter?: React.Dispatch<React.SetStateAction<boolean>>;
    tableHeaderRowRenderer(selectAll: boolean | undefined, selectAllHandler: () => void): JSX.Element;
    tableBodyRowRenderer(
        data: BaseTableRowDataModel<T>,
        rowSelectHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
    ): JSX.Element;
    onSelectionChange?(selectedRows: BaseTableRowDataModel<T>[]): void;
    noResultsTitle?: string;
    noResultsIconName?: StsIconName;
    emptyStateProps?: EmptyProps;
    isPublicUser?: boolean;
    outerTableClassName?: string;
}

export function TableRenderer<T extends Record<string, any>>({
    emptyStateProps,
    isLoading = false,
    isPublicUser,
    noResultsIconName,
    noResultsTitle,
    onSelectionChange,
    outerTableClassName,
    selectAll,
    selectAllSetter,
    tableBodyRowRenderer,
    tableHeaderRowRenderer,
    tableRowsData,
    tableRowsDataSetter,
}: TableRendererProps<T>) {
    const classes = useStyles({ isLoading, isPublicUser });
    let content: JSX.Element | JSX.Element[];
    const tableHeadRef = useRef<HTMLTableSectionElement>(null);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const updateCheckedRows = (updatedRows: BaseTableRowDataModel<T>[]) => {
        const checkedRows = updatedRows.filter((row) => row.checked);
        if (onSelectionChange) {
            onSelectionChange(checkedRows);
        }
        return updatedRows;
    };

    const selectAllHandler = useCallback(() => {
        const newSelectionState = !selectAll;
        const updatedRows = tableRowsData.map((row) => ({ ...row, checked: newSelectionState }));

        tableRowsDataSetter(updateCheckedRows(updatedRows));
        if (selectAllSetter) {
            selectAllSetter(newSelectionState);
        }
    }, [selectAll, tableRowsData, tableRowsDataSetter, selectAllSetter, onSelectionChange]);

    const rowSelectHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.currentTarget;

            const updatedRows = tableRowsData.map((row) => {
                if (row.rowId === value) {
                    return { ...row, checked: !row.checked }; // Toggle checked state
                }
                return row;
            });

            tableRowsDataSetter(updateCheckedRows(updatedRows));

            if (selectAllSetter) {
                selectAllSetter(updatedRows.some((row) => row.checked));
            }
        },
        [tableRowsData, tableRowsDataSetter, selectAllSetter, onSelectionChange]
    );

    const renderLoader = () => (
        <TableRow>
            <TableCell className={classes.loadingCircleContainer}>
                <div className={classes.loaderCircleOuter}>
                    <LoadingCircle size={64} />
                </div>
            </TableCell>
        </TableRow>
    );

    const renderEmptyState = () => (
        <TableRow>
            <TableCell>
                <div className={classes.noResultsOuter}>
                    <Empty
                        description={emptyStateProps?.description || noResultsTitle}
                        icon={emptyStateProps?.icon || noResultsIconName}
                        {...emptyStateProps}
                    />
                </div>
            </TableCell>
        </TableRow>
    );

    const renderRows = () =>
        tableRowsData.map((tableRowData) => (
            <React.Fragment key={tableRowData.id}>
                {tableBodyRowRenderer(tableRowData, rowSelectHandler)}
            </React.Fragment>
        ));

    if (isLoading) {
        content = renderLoader();
    } else if (tableRowsData?.length) {
        content = renderRows();
    } else {
        content = renderEmptyState();
    }

    return (
        <Table className={`${classes.tableOuter} ${outerTableClassName}`}>
            <TableHead ref={tableHeadRef}>{tableHeaderRowRenderer(selectAll, selectAllHandler)}</TableHead>
            <TableBody ref={tableBodyRef}>{content}</TableBody>
        </Table>
    );
}
