import React, { FC } from 'react';
import { default as Modal } from '@packages/ui/shared/modal';
import { EditModalFields, EditModalValue } from './generic-content';
import { StyleSheet, View } from 'react-native';
import { Typography, Button, TextInputHelper } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

export interface ContentModalProps {
    disabled: boolean;
    formData: EditModalValue;
    formFields: EditModalFields[];
    handleHideShowModal: () => void;
    handleSubmit: () => void;
    setFormData: (value: EditModalValue) => void;
    showModal: boolean;
    submitButtonText: string;
    title: string;
}

const styles = StyleSheet.create({
    main: {
        backgroundColor: colors.white,
        borderRadius: 4,
        minWidth: 800,
    },
    header: {
        backgroundColor: colors.grayTwo,
        borderBottomColor: colors.grayThree,
        borderBottomWidth: 1,
        borderRadius: 4,
        justifyContent: 'space-between',
        paddingHorizontal: 24,
        paddingVertical: 16,
    },
    headerTitle: {
        fontSize: 21,
        fontWeight: '600',
        lineHeight: 32,
    },
    labelStyle: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
    row: {
        flexDirection: 'row',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    body: {
        minWidth: 400,
        padding: 24,
    },
    required: {
        color: colors.redOneActive,
    },
    txtWrapper: {
        marginBottom: 24,
    },
    footer: {
        borderTopColor: colors.grayThree,
        borderTopWidth: 1,
        flexDirection: 'row-reverse',
        padding: 24,
    },
    btnContainer: {
        marginRight: 5,
        minWidth: 100,
    },
});

export const GenericContentModal: FC<ContentModalProps> = ({
    disabled,
    formData,
    formFields = [],
    handleHideShowModal,
    handleSubmit,
    setFormData,
    showModal,
    submitButtonText,
    title,
}) => {
    const renderFormField = (field: EditModalFields, index: number) => {
        if (field.type === 'TextInput') {
            return (
                <View style={styles.txtWrapper} key={`${field.fieldName}-${index}`}>
                    <TextInputHelper
                        label={field.label}
                        labelStyle={styles.labelStyle}
                        placeholder={field.placeholder}
                        required={field.required}
                        value={formData[field.fieldName]}
                        onChangeText={(textValue: string) => {
                            setFormData({ ...formData, [field.fieldName]: textValue });
                        }}
                    />
                </View>
            );
        }
        return null;
    };

    return (
        <Modal visible={showModal} onHide={handleHideShowModal}>
            <View style={styles.main}>
                <View style={[styles.header, styles.row, styles.spaceBetween]}>
                    <Typography style={styles.headerTitle} variant="lead">
                        {title}
                    </Typography>
                </View>
                <View style={styles.body}>{formFields.map((field, index) => renderFormField(field, index))}</View>
                <View style={styles.footer}>
                    <View style={styles.btnContainer}>
                        <Button disabled={disabled} onPress={handleSubmit} title={submitButtonText} variant="primary" />
                    </View>
                    <View style={styles.btnContainer}>
                        <Button title="Cancel" variant="ghost-blue" onPress={handleHideShowModal} />
                    </View>
                </View>
            </View>
        </Modal>
    );
};
