import React, { useMemo, useState } from 'react';
import { BodyContainer, ContentSettingsLayout } from './components/content-settings-layout';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { DetailViewHeaderProps } from '@web/components/detail-view';
import { GenericContentManager } from './components/common/generic-content';
import { qmrsDashboardService } from '@web/services/singletons';
import { useNavigate } from 'react-router-dom';
import { useQmrsDashboardState, useQmrsDashboardDispatch } from '@packages/contexts/qmrsdashboard/qmrsdashboard.hooks';
import { useTranslation } from 'react-i18next';
import {
    createOrUpdateCounterMeasureOfTheMonth,
    createOrUpdateHaveYouSeen,
    createOrUpdateHelpfulLink,
    createOrUpdateQmrsDashboadMessage,
    deleteCounterMeasureOfMonth,
    deleteDashboardAlertLink,
    deleteHaveYouSeen,
    deleteHelpfulLink,
    fetchAllCounterMeasureOfTheMonth,
    fetchAllHaveYouSeens,
    fetchAllHelpfulLinks,
    fetchQmrsDashboardAlertLink,
} from '@packages/contexts/qmrsdashboard';
import { ToastNotification } from '@web/components/toast-notification';

const contentAddMessage = 'Your content changes were published';
const contentDeletedMessage = 'Content deleted';

export const ContentSettingsQmrsDashboardMain = () => {
    const { t } = useTranslation();
    const qmrsDashboardState = useQmrsDashboardState();
    const qmrsDashboardDispatch = useQmrsDashboardDispatch();
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState<{ show: boolean; message: string }>({ show: false, message: '' });

    const { qmrsDashboardAlertLinks, haveYouSeens, counterMeasuresOfTheMonth, helpfullinks } = qmrsDashboardState;
    const qmrsDashboardAlertLinkContent =
        qmrsDashboardAlertLinks?.map((alertLink: any) => {
            return {
                description: alertLink.description,
                displayOrder: alertLink.displayOrder,
                displayReadMore: 'true',
                headingText: alertLink.title,
                id: alertLink.qmrDashAlertLinksId,
                link: alertLink.link,
            };
        }) || [];
    const haveYouSeenContent =
        haveYouSeens?.map((hys: any) => {
            return {
                description: hys.description,
                displayOrder: hys.displayOrder,
                displayReadMore: 'true',
                headingText: hys.title,
                id: hys.qmrDashHaveYouSeenId,
                link: hys.link,
            };
        }) || [];

    const counterMeasuresOfMonthContent =
        counterMeasuresOfTheMonth?.map((cmts: any) => {
            return {
                description: cmts.description,
                displayOrder: cmts.displayOrder,
                displayReadMore: 'true',
                headingText: cmts.title,
                id: cmts.qmrDashCounterMeasureId,
                link: cmts.link,
            };
        }) || [];

    const helpfulLinksContent = helpfullinks?.map((hpl: any) => {
        return {
            description: hpl.description,
            displayOrder: hpl.displayOrder,
            displayReadMore: 'false',
            headingText: hpl.title,
            id: hpl.qmrDashHelpfulLinksId,
            link: hpl.link,
        };
    });

    useMemo(() => {
        fetchQmrsDashboardAlertLink({
            id: undefined,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        fetchAllHaveYouSeens({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        fetchAllCounterMeasureOfTheMonth({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        fetchAllHelpfulLinks({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
    }, [qmrsDashboardDispatch]);

    const saveBannerChanges = async (createOrUpdateBanner: { [key: string]: string }) => {
        await createOrUpdateQmrsDashboadMessage({
            qmrsDashboardAlertLink: {
                qmrDashAlertLinksId: createOrUpdateBanner.id,
                description: createOrUpdateBanner.description,
                link: createOrUpdateBanner.link,
                title: createOrUpdateBanner.title,
                displayOrder: createOrUpdateBanner.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentAddMessage });
        return createOrUpdateBanner;
    };
    const saveHaveYouSeenValue = async (haveYouSeen: { [key: string]: string }) => {
        await createOrUpdateHaveYouSeen({
            haveYouSeen: {
                qmrDashHaveYouSeenId: haveYouSeen.id,
                title: haveYouSeen.header || haveYouSeen.headingText,
                link: haveYouSeen.link,
                description: haveYouSeen.description,
                displayOrder: haveYouSeen.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentAddMessage });
        return haveYouSeen;
    };

    const saveCounterMeasureOfTheMonths = async (counterMeasureOfMonths: { [key: string]: string }) => {
        await createOrUpdateCounterMeasureOfTheMonth({
            counterMeasureOfTheMonth: {
                qmrDashCounterMeasureId: counterMeasureOfMonths.id,
                title: counterMeasureOfMonths.header || counterMeasureOfMonths.headingText,
                link: counterMeasureOfMonths.link,
                description: counterMeasureOfMonths.description,
                displayOrder: counterMeasureOfMonths.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentAddMessage });
        return counterMeasureOfMonths;
    };

    const saveHelpfulLinks = async (helpfullLink: { [key: string]: string }) => {
        await createOrUpdateHelpfulLink({
            helpFulLink: {
                qmrDashHelpfulLinksId: helpfullLink.id,
                title: helpfullLink.header || helpfullLink.headingText,
                description: helpfullLink.description,
                link: helpfullLink.link,
                displayOrder: helpfullLink.displayOrder,
            },
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentAddMessage });
        return helpfullLink;
    };

    const deleteAlertLinkCb = async (id: string) => {
        await deleteDashboardAlertLink({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentDeletedMessage });
        return id;
    };
    const deleteHaveYouSeenCb = async (id: string) => {
        await deleteHaveYouSeen({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentDeletedMessage });
        return id;
    };
    const deleteCounterMeasureOfMonthCb = async (id: string) => {
        await deleteCounterMeasureOfMonth({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentDeletedMessage });
        return id;
    };

    const deleteHelpFulLinkCb = async (id: string) => {
        await deleteHelpfulLink({
            id,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        });
        setShowToast({ show: true, message: contentDeletedMessage });
        return id;
    };

    const headerProps: DetailViewHeaderProps = useMemo(
        () => ({
            breadcrumbs: [
                { to: '/administration', title: t('administration:breadcrumbs.adminHub', 'Administration Hub') },
                {
                    to: '/administration/content-settings',
                    title: t('administration:breadcrumbs.contentSettings', 'Content Settings'),
                },
                {
                    to: '/administration/content-settings/qmrsdashboard',
                    title: t('administration:breadcrumbs.edit', 'Edit'),
                },
            ],
            subtitle: t('content-setting:qmrDashboard.subtitle', 'Quality Assurance Dashboard'),
            hasBack: true,
            rightSideItems: [
                <Button key="exit" variant="outlinePrimary" onPress={() => navigate(-1)}>
                    Exit
                </Button>,
            ],
        }),
        [t, navigate]
    );

    return (
        <>
            <ToastNotification
                show={showToast.show}
                onClose={() => setShowToast({ ...showToast, show: false })}
                body={
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <Icon name="checkmark" size={20} color="white" />
                        <Typography variant="h6" color="white">
                            {showToast.message}
                        </Typography>
                    </div>
                }
                autoHideTime={2000}
                variant="success"
                position="top-center"
            />
            <ContentSettingsLayout
                headerProps={headerProps}
                message={t(
                    'content-setting:qmrDashboard.displayMessage',
                    'These messages will appear to DSQM users that visit the Quality Assurance Dashboard page.'
                )}
            >
                <BodyContainer divider={true}>
                    <GenericContentManager
                        genericContentProps={{
                            headerProps: { title: 'Banners', buttonLabel: 'Add Banner' },
                            cardProps: { title: 'Banner', hideCopyButton: true },
                            modalProps: {
                                title: 'Banner',
                                submitButtonText: 'Add Banner',
                                formFields: [
                                    {
                                        type: 'TextInput',
                                        fieldName: 'title',
                                        required: true,
                                        contentFieldMapper: 'headingText',
                                        placeholder: 'Write title here',
                                        label: 'Title',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'description',
                                        contentFieldMapper: 'description',
                                        required: true,
                                        placeholder: 'Write copy here',
                                        label: 'Description',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'link',
                                        contentFieldMapper: 'link',
                                        required: false,
                                        placeholder: 'Write Edit here',
                                        label: 'Link',
                                    },
                                ],
                            },
                            contents: qmrsDashboardAlertLinkContent,
                            saveCallback: saveBannerChanges,
                            deleteCallBack: deleteAlertLinkCb,
                            maxInstance: 1,
                            sortingEnabled: false,
                            confirmDeleteModal: true,
                        }}
                    />
                </BodyContainer>

                <BodyContainer divider={true}>
                    <GenericContentManager
                        genericContentProps={{
                            headerProps: { title: 'Have You Seen', buttonLabel: 'Add News' },
                            cardProps: { title: 'News', hideCopyButton: false },
                            modalProps: {
                                title: 'News',
                                submitButtonText: 'Add News',
                                formFields: [
                                    {
                                        type: 'TextInput',
                                        fieldName: 'header',
                                        required: true,
                                        contentFieldMapper: 'headingText',
                                        placeholder: 'Write title here',
                                        label: 'Title',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'description',
                                        contentFieldMapper: 'description',
                                        required: true,
                                        placeholder: 'Write description here',
                                        label: 'Description',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'link',
                                        contentFieldMapper: 'link',
                                        required: false,
                                        placeholder: 'Write link here',
                                        label: 'Link',
                                    },
                                ],
                            },
                            contents: haveYouSeenContent,
                            saveCallback: saveHaveYouSeenValue,
                            deleteCallBack: deleteHaveYouSeenCb,
                            maxInstance: 10,
                            sortingEnabled: true,
                            confirmDeleteModal: true,
                        }}
                    />
                </BodyContainer>

                <BodyContainer divider={true}>
                    <GenericContentManager
                        genericContentProps={{
                            headerProps: { title: 'Improvements of the Month', buttonLabel: 'Add News' },
                            cardProps: { title: 'News', hideCopyButton: false },
                            modalProps: {
                                title: 'News',
                                submitButtonText: 'Add News',
                                formFields: [
                                    {
                                        type: 'TextInput',
                                        fieldName: 'header',
                                        required: true,
                                        contentFieldMapper: 'headingText',
                                        placeholder: 'Write title here',
                                        label: 'Title',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'description',
                                        contentFieldMapper: 'description',
                                        required: true,
                                        placeholder: 'Write copy here',
                                        label: 'Description',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'link',
                                        contentFieldMapper: 'link',
                                        required: false,
                                        placeholder: 'Write Edit here',
                                        label: 'Link',
                                    },
                                ],
                            },
                            contents: counterMeasuresOfMonthContent,
                            saveCallback: saveCounterMeasureOfTheMonths,
                            deleteCallBack: deleteCounterMeasureOfMonthCb,
                            maxInstance: 10,
                            sortingEnabled: true,
                            confirmDeleteModal: true,
                        }}
                    />
                </BodyContainer>

                <BodyContainer>
                    <GenericContentManager
                        genericContentProps={{
                            headerProps: { title: 'Helpful Links', buttonLabel: 'Add Link' },
                            cardProps: { title: 'Link', hideCopyButton: false },
                            modalProps: {
                                title: 'Link',
                                submitButtonText: 'Add Link',
                                formFields: [
                                    {
                                        type: 'TextInput',
                                        fieldName: 'header',
                                        required: true,
                                        contentFieldMapper: 'headingText',
                                        placeholder: 'Write title here',
                                        label: 'Title',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'description',
                                        contentFieldMapper: 'description',
                                        required: true,
                                        placeholder: 'Write copy here',
                                        label: 'Description',
                                    },
                                    {
                                        type: 'TextInput',
                                        fieldName: 'link',
                                        contentFieldMapper: 'link',
                                        required: false,
                                        placeholder: 'Write Edit here',
                                        label: 'Link',
                                    },
                                ],
                            },
                            contents: helpfulLinksContent,
                            saveCallback: saveHelpfulLinks,
                            deleteCallBack: deleteHelpFulLinkCb,
                            maxInstance: 10,
                            confirmDeleteModal: true,
                        }}
                    />
                </BodyContainer>
            </ContentSettingsLayout>
        </>
    );
};
