import React, { FC, useEffect } from 'react';
import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';
import { Toast } from 'react-bootstrap';
import colors from '@packages/core/styles/colors';
import { StyleSheet } from 'react-native';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';

interface ToastNotificationProps {
    autoHideTime?: number;
    body: JSX.Element | string;
    closable?: boolean;
    iconName?: StsIconName;
    onClose: () => void;
    position?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
    show: boolean;
    styles?: React.CSSProperties;
    textColor?: keyof typeof colors;
    variant?: 'success' | 'error' | 'info' | 'warning';
}

const defaultStyles = StyleSheet.create({
    message: {
        fontSize: 15,
        fontWeight: '600',
        lineHeight: 24,
    },
    closeButton: {
        backgroundColor: 'none',
    },
});

const useStyles = createUseStyles({
    body: {
        padding: '8px 16px',
    },
    toastContainer: {
        width: 'auto',
        textAlign: 'center',
        position: 'fixed',
        zIndex: 9999,
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
    },
});

const animationStyles = {
    enter: {
        transform: 'translateY(-120%)',
        opacity: 0,
    },
    enterActive: {
        transform: 'translateY(0)',
        opacity: 1,
        transition: 'transform 500ms ease, opacity 500ms ease',
    },
    exit: {
        transform: 'translateY(0)',
        opacity: 1,
    },
    exitActive: {
        transform: 'translateY(-120%)',
        opacity: 0,
        transition: 'transform 500ms ease, opacity 500ms ease',
    },
};

const getPositionStyle = (position: ToastNotificationProps['position']) => {
    switch (position) {
        case 'top-left':
            return { top: 50, left: 50 };
        case 'top-center':
            return { top: 50, left: '50%', transform: 'translateX(-50%)' };
        case 'top-right':
            return { top: 50, right: 50 };
        case 'bottom-left':
            return { bottom: 50, left: 50 };
        case 'bottom-center':
            return { bottom: 50, left: '50%', transform: 'translateX(-50%)' };
        case 'bottom-right':
            return { bottom: 50, right: 50 };
        default:
            return { top: 50, right: 50 };
    }
};

const getBackgroundColor = (variant: ToastNotificationProps['variant']) => {
    switch (variant) {
        case 'success':
            return colors.textDarkPrimary;
        case 'error':
            return colors.redOne;
        case 'info':
            return colors.blueOne;
        case 'warning':
            return colors.yellowOne;
        default:
            return colors.greenOne;
    }
};

export const ToastNotification: FC<ToastNotificationProps> = ({
    autoHideTime,
    body,
    closable = false,
    iconName,
    onClose,
    position = 'top-center',
    show,
    styles,
    textColor = 'white',
    variant = 'success',
}) => {
    const classes = useStyles();

    useEffect(() => {
        if (show && autoHideTime) {
            const timer = setTimeout(() => {
                onClose();
            }, autoHideTime);
            return () => clearTimeout(timer);
        }
    }, [show, autoHideTime, onClose]);

    const toastStyles: React.CSSProperties = {
        backgroundColor: getBackgroundColor(variant),
        ...(styles || {}),
    };

    return (
        <CSSTransition
            in={show}
            timeout={300}
            onEnter={(node: HTMLElement) => Object.assign(node.style, animationStyles.enter)}
            onEntering={(node: HTMLElement) => Object.assign(node.style, animationStyles.enterActive)}
            onExit={(node) => Object.assign(node.style, animationStyles.exit)}
            onExiting={(node) => Object.assign(node.style, animationStyles.exitActive)}
            unmountOnExit={true}
        >
            <Toast
                style={{
                    ...toastStyles,
                    ...getPositionStyle(position),
                }}
                className={classes.toastContainer}
                onClose={onClose}
                data-testid="toast-notification"
            >
                <Toast.Body className={classes.body}>
                    <div className="d-flex justify-content-between align-items-center">
                        {typeof body === 'string' ? (
                            <Typography variant="caption" color={textColor} style={defaultStyles.message}>
                                {body}
                            </Typography>
                        ) : (
                            body
                        )}

                        {closable && (
                            <Button style={defaultStyles.closeButton} variant="ghost-gray" onPress={onClose}>
                                <Icon color={textColor} name={iconName || 'x-close'} />
                            </Button>
                        )}
                    </div>
                </Toast.Body>
            </Toast>
        </CSSTransition>
    );
};

export default ToastNotification;
