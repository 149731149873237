import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { EditModalFields, EditModalValue, GenericContentCardsContent } from './generic-content';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import { Typography, Icon, Button } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { default as Modal } from '@packages/ui/shared/modal';

export interface ContentCardProps {
    confirmDeleteModal?: boolean;
    content: GenericContentCardsContent;
    contentList: GenericContentCardsContent[];
    formFields: EditModalFields[];
    hideCopyButton: boolean;
    index: number;
    onDelete?: (id: string) => void;
    onSave: (value: EditModalValue) => void;
    setFormData: (value: EditModalValue) => void;
    setShowModal: (isVisible: boolean) => void;
    title: string;
    toggleModalVisibility: () => void;
}

const useStyles = createUseStyles({
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& div:nth-child(2)': {
            marginLeft: '-15px',
        },
    },
});

const styles = StyleSheet.create({
    iconButton: {
        alignItems: 'center',
        borderRadius: 4,
        display: 'flex',
        height: 40,
        justifyContent: 'center',
        padding: 8,
        width: 40,
    },
    trashButton: {
        backgroundColor: colors.redOne,
    },
    header: {
        alignItems: 'center',
        backgroundColor: colors.grayTwo,
        borderColor: colors.grayThree,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderWidth: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        paddingVertical: 12,
    },
    headerLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
    },
    headerRight: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
    },
    body: {
        backgroundColor: colors.white,
        borderColor: colors.grayThree,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderWidth: 1,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    cardType: {
        textTransform: 'uppercase',
    },
    description: {
        color: colors.textDarkSecondary,
        fontSize: 15,
        fontWeight: '400',
    },
    readMore: {
        fontSize: 13,
        fontWeight: '400',
    },
});

const modalStyles = StyleSheet.create({
    main: {
        backgroundColor: colors.white,
        borderRadius: 4,
        minWidth: 528,
    },
    header: {
        backgroundColor: colors.grayTwo,
        borderRadius: 4,
        justifyContent: 'space-between',
        paddingHorizontal: 24,
        paddingVertical: 16,
    },
    headerTitle: {
        fontSize: 21,
        fontWeight: '600',
        lineHeight: 32,
    },
    body: {
        minWidth: 400,
        padding: 24,
    },
    bodyTitle: {
        fontSize: 15,
        fontWeight: '600',
        lineHeight: 24,
        paddingBottom: 8,
    },
    bodyDescription: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
    footer: {
        borderTopColor: colors.grayThree,
        borderTopWidth: 1,
        flexDirection: 'row-reverse',
        padding: 24,
    },
    btnContainer: {
        marginRight: 5,
        minWidth: 75,
    },
});

const DeleteWithConfirm: FC<{
    showDeleteModal: boolean;
    handleHideShowModal: () => void;
    handleDelete: () => void;
}> = ({ showDeleteModal, handleHideShowModal, handleDelete }) => (
    <Modal visible={showDeleteModal} onHide={handleHideShowModal}>
        <View style={modalStyles.main}>
            <View style={[modalStyles.header, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                <Typography style={modalStyles.headerTitle} variant="lead">
                    Delete Content
                </Typography>
            </View>
            <View style={modalStyles.body}>
                <Typography variant="default" style={modalStyles.bodyTitle}>
                    Are you sure you want to delete this content?
                </Typography>
                <Typography variant="default" style={modalStyles.bodyDescription}>
                    It will be removed permanently and cannot be undone.
                </Typography>
            </View>
            <View style={modalStyles.footer}>
                <View style={modalStyles.btnContainer}>
                    <Button onPress={handleDelete} title="Delete" variant="danger" />
                </View>
                <View style={modalStyles.btnContainer}>
                    <Button title="Cancel" variant="ghost-blue" onPress={handleHideShowModal} />
                </View>
            </View>
        </View>
    </Modal>
);

export const ContentCard: FC<ContentCardProps> = ({
    content,
    onDelete,
    formFields,
    toggleModalVisibility,
    hideCopyButton,
    index,
    onSave,
    setFormData,
    setShowModal,
    contentList,
    title,
    confirmDeleteModal = false,
}) => {
    const classes = useStyles();
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const handleCopy = () => {
        const newValue = mapContentToFormFields({
            content,
            formFields,
            defaultValues: { id: '', displayOrder: (contentList.length + 1).toString() },
        });
        onSave(newValue);
    };

    const handleEdit = () => {
        const newValue = mapContentToFormFields({
            content,
            formFields,
            defaultValues: {
                id: content.id || '',
                displayOrder: content.displayOrder || (index + 1).toString(),
                index: `${index + 1}`,
            },
        });
        setFormData(newValue);
        setShowModal(true);
    };

    const handleDelete = () => {
        if (onDelete && content.id) {
            onDelete(content.id);
        }
    };

    return (
        <>
            <View style={styles.header}>
                <View style={styles.headerLeft}>
                    <TouchableHighlight onPress={toggleModalVisibility} style={styles.iconButton}>
                        <div className={classes.iconContainer}>
                            <Icon name="more-dots-vertical" color="grayFive" />
                            <Icon name="more-dots-vertical" color="grayFive" />
                        </div>
                    </TouchableHighlight>
                    <Typography variant="lead" style={{ fontSize: 14, fontWeight: '400' }}>
                        {title}
                    </Typography>
                </View>
                <View style={styles.headerRight}>
                    {hideCopyButton && (
                        <Button variant="ghost-blue" onPress={handleCopy} style={styles.iconButton}>
                            <Icon name="copy" color="blueOne" size={24} />
                        </Button>
                    )}
                    <Button variant="ghost-blue" onPress={handleEdit} style={styles.iconButton}>
                        <Icon name="pencil" color="blueOne" size={24} />
                    </Button>
                    {onDelete && (
                        <Button
                            onPress={confirmDeleteModal ? () => setShowDeleteModal(true) : handleDelete}
                            style={[styles.iconButton, styles.trashButton]}
                        >
                            <Icon name="trash-striped" color="white" size={24} />
                        </Button>
                    )}
                </View>
            </View>
            <View style={styles.body}>
                {content.type && (
                    <Typography variant="h6" style={styles.cardType}>
                        {content.type}
                    </Typography>
                )}
                <Typography variant="h6">{content.headingText}</Typography>
                {content.description && (
                    <Typography variant="default" style={styles.description}>
                        {content.description}
                    </Typography>
                )}
                <View>
                    {content.displayReadMore === 'true' && content.link && (
                        <Typography variant="caption" style={styles.readMore}>
                            Read More
                        </Typography>
                    )}
                    {content.link && (
                        <Typography variant="default" style={styles.readMore}>
                            {content.link}
                        </Typography>
                    )}
                </View>
            </View>
            <DeleteWithConfirm
                showDeleteModal={showDeleteModal}
                handleHideShowModal={() => setShowDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
};

const mapContentToFormFields = ({
    content,
    formFields,
    defaultValues,
}: {
    content: GenericContentCardsContent;
    formFields: EditModalFields[];
    defaultValues: Partial<EditModalValue>;
}): EditModalValue => {
    const initialValues = Object.entries(content).reduce(
        (acc, [key, value]) => {
            acc[key] = value;
            return acc;
        },
        {} as Record<string, any>
    );

    return formFields.reduce(
        (acc, field) => {
            acc[field.fieldName] = initialValues[field.contentFieldMapper] || '';
            return acc;
        },
        { ...defaultValues } as EditModalValue
    );
};
