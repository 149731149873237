import { HttpClient } from '@packages/core/http';
import {
    IDashboardResponse,
    IDashboardUpdateRequest,
    IDashboardCreateRequest,
    IDashboardUpdates,
} from './dashboard.state';

import { DashboardSavedSearchesDto } from '@packages/models/api';

export class DashboardService {
    constructor(private http: HttpClient) {}

    public getDashboard = async ({ ignoreCache = false, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'GET',
            url: '/dashboard',
            ignoreCache,
            signal,
        });
    };

    public createDashboard = async ({
        body,
        ignoreCache = false,
        signal,
    }: {
        body: IDashboardCreateRequest;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'POST',
            url: '/dashboard',
            ignoreCache,
            signal,
            body,
        });
    };

    public updateDashboard = async ({
        body,
        ignoreCache = false,
        signal,
    }: {
        body: IDashboardUpdateRequest;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'PUT',
            url: '/dashboard',
            ignoreCache,
            signal,
            body,
        });
    };

    public getDashboardUpdate = async ({
        ignoreCache = false,
        signal,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'GET',
            url: '/dashboard-update',
            ignoreCache,
            signal,
        });
    };

    public createDashboardUpdate = async ({
        dashboardUpdate,
        signal,
    }: {
        dashboardUpdate: IDashboardUpdates;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'POST',
            url: '/dashboard-update',
            body: {
                dashboardUpdate,
            },
            signal,
        });
    };

    public updateDashboardUpdate = async ({
        dashboardUpdate,
        signal,
    }: {
        dashboardUpdate: IDashboardUpdates;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'PUT',
            url: '/dashboard-update',
            body: {
                dashboardUpdate,
            },
            signal,
        });
    };

    public deleteDashboard = ({ dashboardId, signal }: { dashboardId: string; signal?: AbortSignal }) => {
        return this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: `/dashboard/${dashboardId}`,
        });
    };

    public deleteDashboardUpdates = async ({
        updateDashboardId,
        signal,
    }: {
        updateDashboardId: string;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<IDashboardResponse>({
            method: 'DELETE',
            url: `/dashboard-update/${updateDashboardId}`,
            signal,
        });
    };

    getDashboardSavedSearches(queryParameters: Record<string, any>) {
        return this.http.orchestrateRequest<DashboardSavedSearchesDto>({
            method: 'GET',
            url: `/dashboard/saved-searches${this.http.queryString(queryParameters || {})}`,
        });
    }
}
