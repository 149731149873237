import React, { FC, useState } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useAbortController } from '@packages/core/http';
import {
    useQmrState,
    saveQmr,
    useQmrDispatch,
    useQmrReportSections,
    QmrReportSectionStatus,
    patchQmr,
    updateQmrDtcCodes,
} from '@packages/contexts/qmrs';
import { QmrStatusId } from '@packages/models/api';
import { Icon, Button, TextBadge, Typography } from '@packages/ui/shared';

import { analyticsService, qmrsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import QmrActions from './qmr-actions';

const qmrHeaderStyles = createUseStyles({
    modalContainer: {
        display: 'flex',
    },
    modalIcon: {
        flexBasis: '17%',
    },
});

interface Props {
    setShowSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    setQmrSuccessfullyEscalated: React.Dispatch<React.SetStateAction<boolean>>;
    setQmrAddedToInvestigationSuccessfully: React.Dispatch<React.SetStateAction<string>>;
}

const QmrHeader: FC<Props> = ({
    setShowSubmitted,
    setQmrSuccessfullyEscalated,
    setQmrAddedToInvestigationSuccessfully,
}) => {
    const classes = qmrHeaderStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { qmr, submitAttempted, localPatch } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');
    const toughBookAttachmentsRoute = '/administration/toughbook-attachments';
    const reportSections = useQmrReportSections(submitAttempted);
    const { abortSignalRef } = useAbortController();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const isDraft = qmr?.qmrStatus.qmrStatusId === QmrStatusId.Draft;
    const [noAttachmentsAdded, setNoAttachmentsAdded] = useState(false);

    const canSubmitQmr =
        !isSubmitting &&
        Object.values(reportSections).every((section) => {
            return (
                !!section &&
                (section.status === QmrReportSectionStatus.Completed ||
                    section.status === QmrReportSectionStatus.Optional)
            );
        }) &&
        (!!editRouteMatch ? !!localPatch : true);

    const exitPage = () => {
        if (editRouteMatch) {
            navigate(`/${qmr?.isNqr ? 'nqrs' : 'qmrs'}`, {
                state: location.state,
            });
        } else if (location.state?.from === toughBookAttachmentsRoute) {
            navigate(toughBookAttachmentsRoute, {
                state: location.state,
            });
        } else {
            const historyFromPrevTab = location.search;
            navigate({
                pathname: `/${qmr?.isNqr ? 'nqrs' : 'qmrs'}`,
                search: location.state?.listQueryParams
                    ? '?' + location.state.listQueryParams
                    : historyFromPrevTab
                      ? historyFromPrevTab
                      : undefined,
            });
        }
    };

    async function handleSaveAndExitButtonClick() {
        if (!qmr) {
            return;
        }

        try {
            await saveQmr({
                qmr,
                qmrsService,
                qmrDispatch,
                signal: abortSignalRef.current,
            });

            exitPage();
        } catch (e) {
            alert(e.message);
        }
    }
    async function handleSubmitQMR() {
        if (!qmr) {
            return;
        }

        if (!editRouteMatch) {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_SUBMIT_QMR, { tsId: qmr.stsIdentifier });
        }

        setIsSubmitting(true);

        try {
            if (!!editRouteMatch && !!localPatch) {
                const { dtcCodes, ...qmrPatch } = localPatch;

                if (!!dtcCodes) {
                    await updateQmrDtcCodes({
                        qmrId: qmr.qmrId,
                        qmrsService,
                        qmrDispatch,
                        signal: abortSignalRef.current,
                        dtcCodes: dtcCodes.map((c) => ({
                            dtcCodeId: c.dtcCodeId,
                            freezeFrameDataAvailable: !!c.freezeFrameDataAvailable,
                            markedAsPrimary: !!c.markedAsPrimary,
                        })),
                    });
                }

                await patchQmr({
                    qmrId: qmr.qmrId,
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                    qmrPatch: {
                        ...qmrPatch,
                        qmrStatusId: QmrStatusId.PendingReviewUpdated,
                        qmrSubmit: true,
                    },
                });
            } else {
                const { ...qmrPatch } = localPatch ?? {};
                const updatedQmr = await patchQmr({
                    qmrId: qmr.qmrId,
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                    qmrPatch: {
                        ...qmrPatch,
                        qmrStatusId: QmrStatusId.PendingReview,
                        qmrSubmit: true,
                    },
                });

                if (!updatedQmr) {
                    return;
                }

                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_SUBMITS_QMR, { tsId: qmr.stsIdentifier });
            }

            setIsSubmitting(false);
            setShowSubmitted(true);
            setTimeout(() => {
                exitPage();
            }, 5000);
        } catch (e) {
            setIsSubmitting(false);
            alert(e.message);
        }
    }

    if (!qmr) {
        return null;
    }

    const handleSubmitButtonClick = () => {
        if (qmr?.assets?.length === 0) {
            setNoAttachmentsAdded(true);
        } else {
            setNoAttachmentsAdded(false);
            handleSubmitQMR();
        }
    };

    const handleDeleteQmr = async () => {
        try {
            const response = await qmrsService.deleteQmr(qmr.qmrId, abortSignalRef.current);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            navigate('/qmrs', { replace: true });
        } catch (e) {
            alert(e.message);
        }
    };

    const handleCancelDeleteQmr = () => {
        setIsDeleting(false);
    };

    const handleSkipAttachments = () => {
        setNoAttachmentsAdded(false);
        handleSubmitQMR();
    };

    const handleAddAttachments = () => {
        navigate(`/qmrs/${qmr?.displayIdentifier}#attachments`);
        setTimeout(() => {
            setNoAttachmentsAdded(false);
        }, 1000);
    };

    return (
        <>
            <div className="d-flex align-items-center">
                <div className="mr-4">
                    <Button variant="link" onPress={exitPage}>
                        <Icon name="arrow-back" />
                    </Button>
                </div>
                <div className="mb-0 mr-4">
                    <Typography variant="h5">{qmr?.displayIdentifier}</Typography>
                </div>

                <TextBadge>{qmr?.qmrStatus.description}</TextBadge>
            </div>
            <div className="d-flex align-items-center">
                {qmr?.qmrStatus?.qmrStatusId === 'DRAFT' && (
                    <Typography variant="labelRegular" color="textDarkSecondary">
                        Saved on {qmr.lastUpdatedTimestampDescription}
                    </Typography>
                )}

                {!editRouteMatch && isDraft && (
                    <div className="ml-6">
                        <Button
                            variant="secondary"
                            onPress={handleSaveAndExitButtonClick}
                            isLoading={isSubmitting}
                            title={t('buttons:saveAndExit')}
                        />
                    </div>
                )}

                {(isDraft || editRouteMatch) && (
                    <div className="ml-4">
                        <Button
                            variant="primary"
                            onPress={handleSubmitButtonClick}
                            isLoading={isSubmitting}
                            disabled={!canSubmitQmr}
                            title={editRouteMatch ? t('buttons:saveChanges', 'Save Changes') : t('buttons:submit')}
                        />
                    </div>
                )}

                {qmr.capabilities.deleteQmr && isDraft && (
                    <div className="ml-4">
                        <Button
                            variant="ghost-gray"
                            onPress={() => {
                                setIsDeleting(true);
                            }}
                        >
                            <Icon name="trash" />
                        </Button>
                    </div>
                )}

                {!isDraft && !editRouteMatch && (
                    <QmrActions
                        onRestrictQmrSuccess={() => {}}
                        onUnrestrcitQmrSuccess={() => {}}
                        setQmrSuccessfullyEscalated={setQmrSuccessfullyEscalated}
                        setQmrAddedToInvestigationSuccessfully={setQmrAddedToInvestigationSuccessfully}
                        hideQMRId={true}
                    />
                )}

                <Modal show={isDeleting} onHide={handleCancelDeleteQmr}>
                    <Modal.Header closeButton placeholder={''}>
                        <Modal.Title>
                            {t('modals:deleteQmrConfirm.title', {
                                qmrId: qmr.qmrId,
                                displayName: qmr.displayIdentifier,
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={classes.modalContainer}>
                            <div className={classes.modalIcon}>
                                <Icon name="warning" />
                            </div>
                            <div>
                                <div>
                                    <p>
                                        <b>{t('modals:deleteQmrConfirm.subtitle')}</b>
                                    </p>
                                </div>
                                <div>
                                    <p>{t('modals:deleteQmrConfirm.body')}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onPress={handleCancelDeleteQmr} variant="ghost-blue">
                            {t('modals:deleteQmrConfirm.cancel')}
                        </Button>
                        <Button onPress={handleDeleteQmr} variant="danger">
                            {t('modals:deleteQmrConfirm.delete')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={noAttachmentsAdded}>
                    <Modal.Body>
                        <div className={classes.modalContainer}>
                            <div className={classes.modalIcon}>
                                <Icon name="info" />
                            </div>
                            <div>
                                <p>{t('modals:noAttachmentWarning.message')}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onPress={handleSkipAttachments} variant="ghost-gray">
                            {t('modals:noAttachmentWarning.skip')}
                        </Button>
                        <Button onPress={handleAddAttachments} variant="primary">
                            {t('modals:noAttachmentWarning.add')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default QmrHeader;
