import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { patchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import { useAbortController } from '@packages/core/http';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Typography, Button } from '@packages/ui/shared';
import { qmrsService } from '@web/services/singletons';
import { Modal } from 'react-bootstrap';
import { SelectQmrPrimaryObjectives } from '@web/components/select-qmr-primary-objectives';

const useStyles = createUseStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
    },
    headerText: {
        fontSize: '21px',
    },
});

const PrimaryObjective = ({ viewOnly = false }: any) => {
    const classes = useStyles();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();

    const [qmrCategories, setQmrCategories] = useState([]);
    const [showObjectiveModal, setShowObjectiveModal] = useState(false);
    const [selectedPrimaryObjectives, setSelectedPrimaryObjectives] = useState([]);
    const [showObjectiveConfirmationModal, setShowObjectiveConfirmationModal] = useState(false);
    const [confirmChangeFromNQRToQMR, setConfirmChangeFromNQRToQMR] = useState(false);

    const fetchQmrCategories = async () => {
        try {
            const qmrCategories: any = await qmrsService.fetchQmrCategories();
            if (qmrCategories?.data?.QmrCategories?.length) {
                setQmrCategories(
                    qmrCategories.data.QmrCategories.map((category: any) => ({ ...category, isSelected: false }))
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchQmrCategories();
    }, []);

    const selectedQmrCategories = useMemo(() => {
        return qmrCategories.filter((c: any) => {
            return qmr?.qmrCategories && qmr.qmrCategories.includes(c.qmrCategoryId);
        });
    }, [qmr?.qmrCategories, qmrCategories]);

    const renderQmrCategories = () => {
        if (!selectedQmrCategories?.length) return null;
        return selectedQmrCategories.map((category: any) => {
            return (
                <div>
                    <Typography variant="caption3">{category.description}</Typography>
                </div>
            );
        });
    };

    const handleObjectiveUpdate = useCallback(async () => {
        if (!qmr) {
            return;
        }
        await patchQmr({
            qmrId: qmr.qmrId,
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
            qmrPatch: { qmrCategories: selectedPrimaryObjectives.map((c: any) => c.qmrCategoryId) },
        });
        setShowObjectiveConfirmationModal(false);
        setShowObjectiveModal(false);
    }, [abortSignalRef, qmr, qmrDispatch, selectedPrimaryObjectives]);

    const onPrimaryObjectivesSelected = (selectedOptions: any) => {
        setSelectedPrimaryObjectives(selectedOptions);
    };

    const getPreviousQMRCategoryType = useCallback(() => {
        if (qmr?.qmrCategories?.length && qmrCategories.length) {
            const selectedCategories: any = qmrCategories.filter((c: any) =>
                qmr?.qmrCategories?.includes(c.qmrCategoryId)
            );
            return selectedCategories.length && selectedCategories.some((c: any) => c.qmrCategoryType === 'NQR')
                ? 'NQR'
                : 'QMR';
        }
    }, [qmr?.qmrCategories, qmrCategories]);

    const getCurrentQMRCategoryType = useCallback(() => {
        if (selectedPrimaryObjectives?.length && qmrCategories.length) {
            const currentSelectedQMRCategories = selectedPrimaryObjectives.map(
                (objective: any) => objective.qmrCategoryId
            );
            const selectedCategories: any = qmrCategories.filter((c: any) =>
                currentSelectedQMRCategories.includes(c.qmrCategoryId)
            );
            return selectedCategories.length && selectedCategories.some((c: any) => c.qmrCategoryType === 'NQR')
                ? 'NQR'
                : 'QMR';
        }
    }, [qmrCategories, selectedPrimaryObjectives]);

    const confirmAndUpdateObjective = () => {
        const priviousQMRCategoryType = getPreviousQMRCategoryType();
        const currentQMRCategoryType = getCurrentQMRCategoryType();
        if (priviousQMRCategoryType === currentQMRCategoryType) {
            handleObjectiveUpdate();
        } else {
            if (priviousQMRCategoryType === 'NQR') {
                setConfirmChangeFromNQRToQMR(true);
            } else {
                setConfirmChangeFromNQRToQMR(false);
            }
            setShowObjectiveModal(false);
            setShowObjectiveConfirmationModal(true);
        }
    };

    const renderSelectObjectiveModal = () => {
        return (
            <Modal show={showObjectiveModal} onHide={() => setShowObjectiveModal(false)} size="lg" centered style>
                <Modal.Header placeholder={'updateObjective'}>
                    <b>Update Primary Objective</b>
                </Modal.Header>
                <Modal.Body>
                    <SelectQmrPrimaryObjectives
                        defaultSelected={selectedQmrCategories}
                        onSelect={onPrimaryObjectivesSelected}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={() => setShowObjectiveModal(false)}>
                            Cancel
                        </Button>
                    </div>
                    <Button disabled={!selectedPrimaryObjectives?.length} onPress={confirmAndUpdateObjective}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const renderPrimaryObjectiveConfirmationModal = () => {
        return (
            <Modal
                show={showObjectiveConfirmationModal}
                onHide={() => setShowObjectiveConfirmationModal(false)}
                size="lg"
                centered
                style
            >
                <Modal.Header placeholder={'updateObjective'}>
                    <b>Confirm update and change report type</b>
                </Modal.Header>
                <Modal.Body>
                    <Typography>
                        By selecting this objective(s), you are about to change the{' '}
                        <b>{confirmChangeFromNQRToQMR ? 'NQR to a QMR' : 'QMR to a NQR'}</b>.
                    </Typography>
                    <div>
                        <Typography>Are you sure you want to proceed?</Typography>
                    </div>
                    <div className="mt-5">
                        <Typography>
                            *Note: Changing the report type will move it to corresponding flow and section.
                        </Typography>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <div>
                        <Button
                            variant="ghost-blue"
                            onPress={() => {
                                setShowObjectiveConfirmationModal(false);
                                setShowObjectiveModal(true);
                            }}
                        >
                            Back
                        </Button>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="mr-2">
                            <Button variant="ghost-blue" onPress={() => setShowObjectiveConfirmationModal(false)}>
                                Cancel
                            </Button>
                        </div>
                        <Button onPress={() => handleObjectiveUpdate()}>Confirm</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div>
            <div className={classes.header}>
                <Typography style={{ fontSize: 21 }} variant="caption3">
                    Primary objective
                </Typography>
                {!viewOnly ? (
                    <button
                        onClick={() => setShowObjectiveModal(true)}
                        type="button"
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}
                    >
                        <Typography variant="h6" color="blueOne">
                            Change Primary Objective
                        </Typography>
                    </button>
                ) : (
                    <></>
                )}
            </div>
            <div className="mt-3">{renderQmrCategories()}</div>
            {renderSelectObjectiveModal()}
            {renderPrimaryObjectiveConfirmationModal()}
        </div>
    );
};

export default PrimaryObjective;
